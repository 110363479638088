import React, { useEffect, useState, useMemo } from 'react';
import { Icon, Row, Col, Collapse, Button, Tooltip, Switch, Modal, Divider} from "antd";
import { getSimilarAspectRatio } from "../services/display";
import ScreensGroupHolder from "./Styles/screensGroupStyle";
import { useTranslation } from 'react-i18next';
import Dotdotdot from "react-dotdotdot";
import Slider from "react-slick";
import RulesContainer from "../containers/RulesContainer";
import ListRulesComponent from "./ListRulesComponent";
import ScreenContainer from "../containers/ScreenContainer";
import RelationSliderComponent from "./RelationSliderComponent";
import AudienceImpactsComponent from "./AudienceImpactsComponent";
import { hasAccessRole, calculeDaysOfCampaign, calculeValuesPerGroup} from '../services/utils';
import UserRoleEnum from "../constants/UserRoleEnum";
import { getCpmByStatusCampaign } from "../services/display";
import NumberFormat from "./NumberFormat";
import ModalEditCampaignManagerComponent from "./ModalEditCampaignManagerComponent";
import GenerateSkeletonComponent from './GenerateSkeletonComponent';
import ContentSpecificationAlertComponent from './ContentSpecificationAlertComponent';
import { getImpressionsToShowPerDay } from "../services/display"
import LazyRender from './HighOrderComponent';
import TotalInvestmentComponent from './TotalInvestmentComponent';

const { Panel } = Collapse;

const ScreensGroupComponent = (props) => {

  const { t } = useTranslation();
  const {
    user,
    toggleFillScreen,
    customContents,
    audience,
    editCampaignActive,
    updateImpressionsDisplay,
    currentCampaign,
    managerDisplayFilter,
    disabledManagerDisplayFilter,
    disableManagerDisplayFilter,
    displaysCollection,
    reportSingleData,
    cart,
    date_from,
    date_to,
    programmatic,
    updateContentName,
    updateListOfContentsInCampaign,
    loadingSingleReport,
    currency,
    applyRelationOnScreens,
    allDisplaysAreOwn,
    updateSubsidizedPrice,
    removeRelationsOnCampaign,
    audienceHoursOfDay,
    audienceDaysOfWeek,
    getAudienceHoursOfDayRequest,
    getAudienceDaysOfWeekRequest,
    creditAvailableOnTransferredImpressions,
    updateCreditAvailable,
    isPaymentStatusApproved,
    setActive,
    setIsCampaignPaused,
    updatePromisedShowsSubsidized,
    removeDisplayFromCart,
    removeDisplayOnCampaign,
    updateCustomContents,
    displays
  } = props;

  // Modal width
  const modalWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth > 992 ? 900 : window.innerWidth;

  const [selectDisplayForRules, setSelectDisplayForRules] = useState(null);
  const [selectGroupDisplayRules, setSelectGroupDisplayRules] = useState(null);

  const [relationToDelete, setRelationToDelete] = useState(null);
  const [displaysGroups, setDisplaysGroups] = useState([]);

  const [showScreenDetail, setShowScreenDetail] = useState(false);
  const [displayModalInfo, setDisplayModalInfo] = useState(null);
  const [displayModalUpdateContent, setDisplayModalUpdateContent] = useState(null);
  const [indexGroup, setIndexGroup] = useState(null);

  const filteredContentsByDisplay = useMemo(() => {
    const result = {};
    customContents.forEach((item) => {
      if (!result[item.displayId]) {
        result[item.displayId] = [];
      }
      result[item.displayId].push(item);
    });
    return result;
  }, [customContents]);

  const customPanelStyleContent = {
    borderTopWidth: "0px",
    borderLeftWidth: "0px",
    borderRightWidth: "0px",
  }

  const displayMap = new Map(displaysCollection.map(display => [display.id, display.cpmi]));
  const getAvgCPM = (contentsDisplay) => {
    if (!contentsDisplay.length || !displayMap.size) return 0;
    let totalCPM = 0;
    let totalCount = 0;

    contentsDisplay.forEach(({ displayId }) => {
      const cpmi = displayMap.get(displayId);
      if (cpmi !== undefined) {
        totalCPM += cpmi;
        totalCount++;
      }
    });

    return totalCount ? totalCPM / totalCount : 0;
  };

  const groupByDimension = () => {
    const groups = [];

    displaysCollection.forEach(display => {
      const indice = getSimilarAspectRatio(display.resolution_width, display.resolution_height, t);
      const groupIndex = groups.findIndex(item => item.key === indice);
      display = {
        ...display,
        fill_screen: customContents.some(item => item.displayId === display.id && item.fill_screen === 1) ? 1 : 0
      };
      if (groupIndex === -1) {
        groups.push({ key: indice, displays: [display] });
      } else {
        groups[groupIndex].displays.push(display);
      }
    });
    return groups;
  };

  // Toggle fill screen for multiple relations for this display
  const groupToggleFillScreen = ({ key, displays, fill_screen }) => {
    let newFillScreen = fill_screen === 1 ? 0 : 1;  // change status of fill screen
    displays.forEach(screen => {
      toggleFillScreen(screen.id, screen.relationId, newFillScreen, 'multiple');
    });

    let newStateGroup = displaysGroups.map(group => {
      if (key === group.key) {
        return { ...group, fill_screen: newFillScreen };
      }
      return group; // Added return statement for unchanged groups
    });
    setDisplaysGroups([...newStateGroup]);
  }

  // Toggle fill screen for a single relation for this display
  const displayToggleFillScreen = (display) => {
    let newFillScreen = customContents.some(item => item.displayId === display.id && item.fill_screen === 1) ? 0 : 1;
    setDisplaysGroups(displaysGroups.map(group => {
      if (group.displays.find(item => item.id === display.id)) {
        // if not exist fill_screen in display, set 1 because is the first time
        let newFillScreen = display.fill_screen === undefined ? 1 : (display.fill_screen === 1 ? 0: 1);
        return { ...group, displays: group.displays.map(d => d.id === display.id ? { ...d, fill_screen: newFillScreen } : d) };
      }
      return group;
    }));
    toggleFillScreen(display.id, null, newFillScreen, 'multiple')
  }

  const fixedBudgetInDisplay = (displays, isFixedBudgetDisplay) => {
    const displayIds = displays.map(display => display.id);

    const auxcustomContentsAux = customContents.map(item => {
      if (displayIds.includes(item.displayId)) {
        return {
          ...item,
          isFixedBudget: !isFixedBudgetDisplay
        };
      }
      return item;
    });

    updateCustomContents(auxcustomContentsAux);
  };

  const getTooltipForFixedBudget = ( type, status ) => {

    let typeAux = t('this spot')
    typeAux = type === "display" ? t("this display") : typeAux
    typeAux = type === "groupDisplay" ? t("this displays group") : typeAux
    let tooltip = ""
    if(!status) {
      tooltip = t("When setting the amount for (n_type), it will not be modified during the automatic budget redistribution. It can be adjusted manually at any time.", { n_type: typeAux });
    } else {
      tooltip = t("If the amount for (n_type) is not fixed, it may be modified during the automatic budget redistribution. It can also be adjusted manually at any time.", { n_type: typeAux });
    }

    return tooltip
  }

  const getContentDisplayByScreenGroup = (displaysGroup) => {
    let listContentDisplay = []
    displaysGroup.map(screen => {
      let customContent =  customContents.filter(item => item.displayId === screen.id)
      listContentDisplay = [...listContentDisplay, ...customContent]
    })
    return listContentDisplay
  }

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleShowScreenDetail = (defaultTabScreenDetail, display=null) => {
    if (defaultTabScreenDetail === "close") {
      setDisplayModalInfo(null)
      setShowScreenDetail(!showScreenDetail);
      return
    }
    setDisplayModalInfo(display)
    setShowScreenDetail(!showScreenDetail);
  };

  const handleModalUpdateContent = (display) => {
    setDisplayModalUpdateContent(display);
    if (!display) {
      setDisplayModalUpdateContent(null)
      return
    }
    setDisplayModalUpdateContent(display)
  };

  const durationCampaign = calculeDaysOfCampaign(date_from, date_to);

  function calculateTotalShows(contentsDisplay) {
    return contentsDisplay.reduce((total, relation) => {
      if (reportSingleData && reportSingleData.report) {
        const foundElement = reportSingleData.report.find(element =>
          element.display === relation.displayId && element.content === relation.content.id
        );
        if (foundElement) {
          total += relation.promised_shows - foundElement.shows;
        } else {
          total += relation.promised_shows;
        }
      } else {
        total += relation.promised_shows;
      }

      return total;
    }, 0);
  }

  const spotsPerDate = (contentsDisplay) => {
    const promiseShowPerDay = contentsDisplay.reduce((total, relation) => {
      const impressionWithRules = getImpressionsToShowPerDay (date_from, date_to, relation.rules);
      const promisedShowsWithRules = impressionWithRules === 0 ? 0 : relation.promised_shows / impressionWithRules;
      if (reportSingleData && reportSingleData.report) {
        const foundElement = reportSingleData.report.find(element =>
          element.display === relation.displayId && element.content === relation.content.id
        );
        if (foundElement) {
          total += promisedShowsWithRules - foundElement.shows;
        } else {
          total += promisedShowsWithRules;
        }
      } else {
        total += promisedShowsWithRules;
      }

      return total;
    }, 0);
    return Math.round(promiseShowPerDay);
  }

  const headerPanel = (groupDisplay, index) => {
    const {key, displays} = groupDisplay
    // search the custom contents for the displays of the group
    let contentsDisplay = customContents.filter(item => displays.some(display => display.id === item.displayId));
    let investmentGroup, impactsGroup
    const option = editCampaignActive ? { currentCampaign: currentCampaign, calculateImpacts: true }: {calculateImpacts: true};
    [ investmentGroup, impactsGroup ] = calculeValuesPerGroup(groupDisplay, contentsDisplay, audience, option);

    const totalPromisedShows = calculateTotalShows(contentsDisplay)
    const impressionsPerDay = spotsPerDate(contentsDisplay);
    const isFixedBudgetDisplay = contentsDisplay.every(item => item.isFixedBudget === true);
    return (
      <Row style={{ alignItems: 'stretch' }}>
        <Col xs={5} sm={24} md={24} lg={24} xl={3} className="col-displays-header">
          <span>
            <div className='textGroupColor'>{key}</div>
            <div className="content-info">
              {displays.length} {displays.length === 0 ? t("display") : t("displays")}
            </div>
            <div style={{textAlign: "center", paddingTop: "15px", paddingRight: "10px"}}>
              <ContentSpecificationAlertComponent
                displays={displays}
                customContentsFiltered={contentsDisplay}
              />
            </div>
          </span>
          <Divider type="vertical" style={{color: "black", height: "10em"}}/>
        </Col>
        <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
          <span>
            <ListRulesComponent
              listContentDisplay={getContentDisplayByScreenGroup(displays)}
              styleRules="center"
              type="group"
              defaultText={t('Without rules in group')}
              showModalRules={() => showModalGroupDisplayRules(key)}>
            </ListRulesComponent>
          </span>
          <Divider type="vertical" style={{color: "black", height: "10em"}}/>
        </Col>
        <Col xs={5} sm={24} md={24} lg={24} xl={ programmatic === "programmatic" ? 4 : 12 } className="col-displays-header">
          <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Tooltip title={t('Add contents to screen group')}>
                <Button
                    className='buttonActionsGroup'
                    shape="circle"
                    onClick={(e) => {
                        stopPropagation(e);
                        setIndexGroup(index);
                    }}
                >
                    <Icon type="plus" />
                </Button>
            </Tooltip>
            <Tooltip title={ !groupDisplay.fill_screen  ? t('Expand ad') : t('Return original resolution')}>
              <Button
                className='buttonActionsGroup'
                shape="circle"
                onClick={(event) => {
                  stopPropagation(event);
                  groupToggleFillScreen(groupDisplay)
                }}
              >
                { !groupDisplay.fill_screen ? (<Icon type="fullscreen"/> ) : ( <Icon type="fullscreen-exit"/> )}
              </Button>
            </Tooltip>
            <Tooltip title={t('Set up rules in group of displays')}>
              <Button
                shape="circle"
                className='buttonActionsGroup'
                onClick={(event) => {
                    stopPropagation(event);
                    showModalGroupDisplayRules(key)
                }}>
                <Icon type="control" />
              </Button>
            </Tooltip>
            <br/>
            <Tooltip
              placement="top"
              title={displaysGroups.length < 2 ? t('To delete need at least two group in the campaign') : t('Remove group')}>
              <Button
                shape="circle"
                className='buttonActionsGroup'
                disabled = {displaysGroups.length < 2}
                onClick={(event) => {
                  showModalDeleteRelation('deleteGroupDisplays', displays);
                  stopPropagation(event)}}
              >
                  {<Icon type="close"/>}
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={getTooltipForFixedBudget("groupDisplay", isFixedBudgetDisplay)}>
              <Button
                shape="circle"
                className='buttonActionsGroup'
                onClick={(event) => {
                  fixedBudgetInDisplay(displays, isFixedBudgetDisplay);
                  stopPropagation(event)
                }}>
                {<i
                    className={ isFixedBudgetDisplay ? "fa fa-lock" : "fa fa-unlock"}
                    aria-hidden="true"
                    style={{ color: "gray", cursor: "pointer", fontSize: "large"}}
                  ></i>}
              </Button>
            </Tooltip>
          </span>
          <Divider type="vertical" style={{color: "black", height: "10em"}}/>
        </Col>
        { programmatic === "programmatic" && (
        <>
        <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
            <span style={{display: "flex",flexDirection: "column" }}>
              <div className='textGroup'> {t('Impressions')}</div>
              <div className='textGroup'>
                { !totalPromisedShows ?
                  <div style={{paddingLeft: 10}}>
                    <GenerateSkeletonComponent count={1} height={15} width={100} />
                  </div>:
                  <span className="amountCampaignPerDisplay" style={{marginLeft: 3}}>
                    { totalPromisedShows }
                  </span>
                }
              </div>
              <div className="content-info">
                {impressionsPerDay} {t("spots/day")}
              </div>
            </span>
            <Divider type="vertical" style={{color: "black", height: "10em"}}/>
          </Col>
          <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header">
            <span style={{display: "flex",flexDirection: "column" }}>
              <div className='textGroup'> {t('Investment')}</div>
              <div className='textGroup'>
                <span className="amountCampaignPerDisplay" style={{marginLeft: 3}}>
                  <NumberFormat
                    tax_include={false}
                    value={investmentGroup}
                    currency={editCampaignActive ? currentCampaign.currency:currency}
                  />
                </span>
              </div>
              <div className="content-info">
                <Tooltip
                  placement="right"
                  title={t('(CPM) cost per thousand impression')}>
                  <NumberFormat
                    value ={getAvgCPM(contentsDisplay)}
                    currency={currency}
                  /> {t("CPM")}
                </Tooltip>
              </div>
            </span>
            <Divider type="vertical" style={{color: "black", height: "10em"}}/>
          </Col>
        </>
        )}
        <Col xs={5} sm={24} md={24} lg={24} xl={4} className="col-displays-header" style={{marginTop: "15px"}}>
          <div>
            <div className='textGroup'> {t("Audience")}</div>
            <AudienceImpactsComponent
              displays={displays}
              audience={audience}
              audienceHoursOfDay = {audienceHoursOfDay}
              audienceDaysOfWeek = {audienceDaysOfWeek}
              getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
              getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest} />
          </div>
        </Col>
      </Row>
    )
  }

  // Remove and update impressions of campaign
  const removeRelationFromDisplayView = (relationToDelete) => {

    const {typeDelete, displayId, relationId} = relationToDelete;
    // Remove the display from the cart and the relation
    if (typeDelete === 'deleteDisplay') {
      removeDisplayFromCart(displayId)
      removeDisplayOnCampaign(displayId)
    }

    // Remove the display from the displaysCollection and the relation
    if (typeDelete === 'deleteGroupDisplays') {
      displayId.forEach(display => {
        removeDisplayFromCart(display.id)
        removeDisplayOnCampaign(display.displayId)
      })
    }

    if (typeDelete === 'deleteRelation') {
      // Remove the relation from the display
      let contentDisplayForDelete = customContents.find(item => item.relationId === relationId)
      let contentDisplayForUpdateImpression = customContents.filter(item => item.displayId === displayId)
      removeRelationsOnCampaign( displayId, contentDisplayForDelete.content.id )
      let countCustomesContent = 0
      countCustomesContent = customContents.filter(customContent => customContent.displayId === displayId).length
      countCustomesContent = countCustomesContent - 1

      let impressions = Math.trunc(contentDisplayForDelete.promised_shows / countCustomesContent)
      // if (editCampaignActive && currentCampaign.payment_status === 'approved') {
      //   // updateImpressions(customContents, false);
      // } else {
        contentDisplayForUpdateImpression.forEach(item => {
            let newPromisedShows = item.promised_shows + impressions;
            updateImpressionsDisplay(item.displayId, item.relationId, newPromisedShows);
        });
      // }
    }

    showModalDeleteRelation();
    return;
  };

  // Variable to show the modal of the display when applying rules
  const showModalDisplayRules = (display = null) => {
      setSelectDisplayForRules(display ? display.id : null)
  }

  const fixedBudget = (customContentUpdate) => {
    const customContentsAux = customContents.map(customContent => {
      if ( customContent.relationId === customContentUpdate.relationId ) {
        return { ...customContent, isFixedBudget: !customContentUpdate.isFixedBudget}
      }
      return customContent;
    });
    updateCustomContents(customContentsAux)
  }

  // Variable to show the modal of the group of displays when applying rules
  const showModalGroupDisplayRules = (indice = null) => {
    setSelectGroupDisplayRules(indice ? indice: null)
  }

  const showModalDeleteRelation = (typeDelete = null, displayId = null, relationId = null) => {
    if (!typeDelete) {
      setRelationToDelete(null);
      return;
    }
    
    let title = '';
    let description = '';
    
    if (typeDelete === 'deleteRelation') {
      title = t('Remove screen from campaign');
      description = t('The ad will be removed from the screen, and the spots will be redistributed among the other versions. You can add it again from the previous Ads step or by clicking the Add Ad button.');
    }

    if (typeDelete === 'deleteDisplay') {
      title = t('Remove screen from campaign');
      description = t('You are going to remove this screen from the campaign');
    }

    if (typeDelete === 'deleteGroupDisplays') {
      title = t('Remove group of screens');
      description = t('By removing the group, all screens within this group will be removed from the campaign.');
    }

    setRelationToDelete({
      typeDelete,
      displayId,
      relationId,
      title,
      description
    });
  };

  const getAspectRatio = (width, height) => {
    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));
    const divisor = gcd(width, height);
    return `${width / divisor}:${height / divisor}`;
  };

  const getApproximateAspectRatio = (width, height) => {
    const tolerance = 0.5;
    const aspect = width / height;
    const roundedAspect = Math.round(aspect / tolerance) * tolerance;
    return roundedAspect.toFixed(2);
  };

  const setGroupReduce = (acc, display, filter ) => {
    if (!acc || !display || !filter) return [];
    if (!acc[filter]) {
      acc[filter] = { key: filter, displays: [] };
    }
    acc[filter].displays.push(display);
    return acc;
  }

  const groupByAspectRatio = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const aspectRatio = getAspectRatio(display.resolution_width, display.resolution_height);
      const approximateAspectRatio = getApproximateAspectRatio(display.resolution_width, display.resolution_height);
      if (!acc[approximateAspectRatio]) {
        acc[approximateAspectRatio] = {
          key: aspectRatio,
          displays: []
        };
      }

      acc[approximateAspectRatio].displays.push(display);
      return acc;
    }, {}));
  };

  const groupByResolution = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const resolution = `${display.resolution_width}x${display.resolution_height}`;

      return setGroupReduce(acc, display, resolution);
    }, {}));
  };

  const groupByLocationType = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const locationType = display.location_type;

      return setGroupReduce(acc, display, locationType);
    }, {}));
  };

  const groupBySubType = () => {
    return Object.values(displaysCollection.reduce((acc, display) => {
      const name_type = display.open_ooh_venue_type_id ? `subtype_${display.open_ooh_venue_type_id}` : "Not assigned";
      const subtype = t(name_type);

      return setGroupReduce(acc, display, subtype);
    }, {}));
  };

  const updateSubsidizedPriceDisplay = (displayId, checked) => {
      updateSubsidizedPrice(displayId, checked, 'display')
  };

  function getListContentsCompatible(display){
    return Object.keys(display.format_settings)
        .filter(format => display.format_settings[format])
        .map(format => {
          switch (format) {
          case 'image':
          return t('Image');
          case 'video':
          return t('Video');
          case 'html':
          return t('HTML');
          default:
          return format.charAt(0).toUpperCase() + format.slice(1);
          }
        }).join(' - ')
  }

  const groupAccordingToFilter = () => {
    let oldDisplaysGroups = displaysGroups;
    let displaysGroupAux

    if (managerDisplayFilter === "aspect") displaysGroupAux = groupByAspectRatio();
    if (managerDisplayFilter === "dimensions") displaysGroupAux = groupByDimension();
    if (managerDisplayFilter === "resolution") displaysGroupAux = groupByResolution();
    if (managerDisplayFilter === "screen") displaysGroupAux = groupByLocationType();
    if (managerDisplayFilter === "subtype") displaysGroupAux = groupBySubType();

    // Defined the fill screen for the group of displays
    if (displaysGroupAux[0] && displaysGroupAux[0].fill_screen === undefined) {
      displaysGroupAux = displaysGroupAux.map(item => {
        let groupItem = oldDisplaysGroups.find(oldItem => oldItem.key === item.key);
        return {
          ...item,
          fill_screen: groupItem ? groupItem.fill_screen : 0
        };
      });
    }

    setDisplaysGroups(displaysGroupAux);
    disableManagerDisplayFilter(false);
  };

  const handleIsPausedToggle = (isCampaignPaused, display) => {
    const active = isCampaignPaused ? 0 : 1;
    const customContentsDisplay = customContents.filter(customContent => customContent.displayId === display.id);

    customContentsDisplay.forEach(item => {
      setActive(item.relationId, active);
    });
  };

  const displayIsPaused = (display) => {
    const customContentsDisplay = customContents.filter(customContent => customContent.displayId === display.id);
    const isPaused = customContentsDisplay.every(item => item.active === 0)

    return isPaused;
  };

  useEffect(() => {
    groupAccordingToFilter();
  }, [cart, managerDisplayFilter, displaysCollection ]);

  return (
    <ScreensGroupHolder>
      <Row type="flex" className="screens-group">
        {!disabledManagerDisplayFilter && displaysGroups.map((group, index) => (
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="appear-animation customPanelStyleDisplays" key={group.key}>
            <Collapse style={customPanelStyleContent}>
              <Panel
                header={headerPanel(group, index)}
                key={group.key}
              >
                <div className="horizontal-line"></div>
                {group.displays.map((display, indexDisplay) => {
                  const customContentsFiltered = customContents.filter(item => item.displayId === display.id);
                  const listContentsCompatible = getListContentsCompatible(display)
                  const isFixedBudgetDisplay = customContentsFiltered.every(item => item.isFixedBudget === true);
                  return (
                    <div style={{marginTop:"35px"}} key={display.id}>
                      <Row key={display.id} style={{display: "flex"}}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4} style={{paddingRight:"10px"}}>
                          <div>
                            <div className="textGroup" style={{marginBottom: "10px",textAlign: "center"}}>
                              <Tooltip placement="top" title={t("Screen detail")}>
                                <Button
                                  shape="circle"
                                  className='buttonActionsDisplayGroup'
                                  onClick={() => handleShowScreenDetail("info", display)}
                                  size='small'
                                  >
                                  <Icon type="info-circle" theme="outlined" />
                                </Button>
                              </Tooltip>
                              <span>
                                {display.name}
                              </span>
                            </div>
                              <div style={{display: "flex", flexDirection: "column"}}>
                                  <div style={{textAlign: "center"}}>
                                    <ContentSpecificationAlertComponent
                                      displays={[display]}
                                      customContentsFiltered={customContentsFiltered}
                                    />
                                  </div>
                                  { selectDisplayForRules === display.id  &&
                                    <Modal
                                      key={display.id}
                                      title={t("Rules in campaign")}
                                      visible={ selectDisplayForRules === display.id }
                                      footer={[
                                        <Button key="ok" type="primary" onClick={() => showModalDisplayRules()}>
                                          {t("Close")}
                                        </Button>,
                                      ]}
                                      onCancel={() => showModalDisplayRules()}
                                      width={800}
                                    >
                                      <RulesContainer
                                        key={display.id}
                                        typeModal="display"
                                        typeName={ display.name }
                                        listContentDisplay={customContentsFiltered}
                                    />
                                    </Modal>
                                  }
                                  <div className='tagsContainer'>
                                    {listContentsCompatible.split(' - ').map((format, index) => (
                                      <div key={index} className="compatibleContent">
                                        <Icon
                                          style={{ fontSize: "15px", marginRight: "5px"}}
                                          type={
                                            format === t('Image') ? 'picture' :
                                            format === t('Video') ? 'play-circle' :
                                            format === t('HTML') ? 'code' :
                                            'file'
                                          }
                                        />
                                        {format}
                                      </div>
                                    ))}
                                    <div className="compatibleContent">
                                      Px {display.resolution_width}x{display.resolution_height}
                                    </div>
                                    <Tooltip
                                      placement="top"
                                      title={t('Screen space time')}
                                    >
                                      <div className="compatibleContent">
                                        <Icon
                                          style={{ fontSize: "15px", marginRight: "5px"}}
                                          type="clock-circle"
                                        />
                                        {display.slot_length / 1000} {t('secs')}
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div style={{textAlign:"center", marginTop: "10px", marginBottom:"10px"}}>
                                    <ListRulesComponent
                                      listContentDisplay={ customContentsFiltered}
                                      styleRules="center"
                                      type="display"
                                      defaultText={t('Without rules in display')}
                                      showModalRules={() => showModalDisplayRules(display)}>
                                    </ListRulesComponent>
                                  </div>
                              </div>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={17} className="contentRelation" style={{paddingRight:"10px"}}>
                          <div>
                            <LazyRender>
                              <RelationSliderComponent
                                audience={audience}
                                display={display}
                                Slider={Slider}
                                customContents={customContents}
                                reportSingleData={reportSingleData}
                                currentCampaign={currentCampaign}
                                editCampaignActive={editCampaignActive}
                                showModalDeleteRelation={showModalDeleteRelation}
                                toggleFillScreen={toggleFillScreen}
                                date_to={date_to}
                                date_from={date_from}
                                programmatic={programmatic}
                                currency={currency}
                                updateImpressionsDisplay={updateImpressionsDisplay}
                                updateContentName={updateContentName}
                                updateListOfContentsInCampaign={updateListOfContentsInCampaign}
                                loadingSingleReport={loadingSingleReport}
                                user={user}
                                allDisplaysAreOwn={allDisplaysAreOwn}
                                updateSubsidizedPrice={updateSubsidizedPrice}
                                applyRelationOnScreens={applyRelationOnScreens}
                                audienceHoursOfDay = {audienceHoursOfDay}
                                audienceDaysOfWeek = {audienceDaysOfWeek}
                                getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
                                getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest}
                                cart={cart}
                                creditAvailableOnTransferredImpressions={creditAvailableOnTransferredImpressions}
                                updateCreditAvailable = {updateCreditAvailable}
                                isPaymentStatusApproved={isPaymentStatusApproved}
                                updatePromisedShowsSubsidized={updatePromisedShowsSubsidized}
                                cpmDisplay={getCpmByStatusCampaign(editCampaignActive, currentCampaign , display)}
                                setIsCampaignPaused={setIsCampaignPaused}
                                setActive= {setActive}
                                displays={displays}
                                updateCustomContents= {updateCustomContents}
                                fixedBudget= {fixedBudget}
                                getTooltipForFixedBudget={getTooltipForFixedBudget}
                              />
                            </LazyRender>
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={3} style={{ paddingRight:"5px", display:"flex", justifyContent: "center"}}>
                          <div style={{paddingLeft:"15px",display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <div style={{textAlign: "center"}}>
                              <div className='textGroup'> {t("Audience")}</div>
                              <TotalInvestmentComponent
                                currency={currency}
                                display={display}
                                displays={display}
                                audience={audience}
                                type='display'
                                user={user}
                                editCampaignActive={editCampaignActive}
                                audienceHoursOfDay = {audienceHoursOfDay}
                                audienceDaysOfWeek = {audienceDaysOfWeek}
                                getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
                                getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest}
                                customContents={customContents}
                                cpmDisplay={getCpmByStatusCampaign(editCampaignActive, currentCampaign , display)}
                                />
                            </div>
                            {(editCampaignActive
                              && currentCampaign.payment_status !== 'pending'
                              && currentCampaign.payment_status !== 'cancelled'
                              && user
                              && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) ?
                              (
                                <div style={{ marginBottom: 10, marginTop: 10, textWrapMode: "nowrap" }}>
                                  <span>
                                    {t('Pause display')}
                                    &emsp;
                                    <Switch
                                      onChange={(value) => handleIsPausedToggle(value, display)}
                                      checked={displayIsPaused(display)}
                                      size="small"
                                    />
                                  </span>
                                </div>
                              ) : null}
                            {!editCampaignActive ?
                              <>
                                {(user && (hasAccessRole(user.roles, [UserRoleEnum.superAdmin, UserRoleEnum.superModeratorAgency]) || allDisplaysAreOwn)) ? (
                                    <div style={{ marginBottom: 5, marginTop: 10 }}>
                                        {t('Subsidize')}
                                        &emsp;
                                        <Switch
                                          checked = {customContentsFiltered.every(item => item.subsidized_price)}
                                          onClick={(checked) => updateSubsidizedPriceDisplay(display.id, checked)}
                                          size="small"
                                        />
                                      <Tooltip placement="bottomLeft" title={t('By enabling this option, the screen is subsidize, affecting the price of the campaign')}>
                                        <Icon
                                          style={{ fontSize: "20px", marginLeft: "10px", color: "#f7d455", verticalAlign: "bottom"}}
                                          type="warning"
                                        />
                                      </Tooltip>
                                    </div>
                                ) : ( null ) }
                              </>:<>
                                  { customContentsFiltered.every(item => item.subsidized_price) ?
                                    ( display.promised_shows != 0 ?
                                      <span className="ant-tag ant-tag-orange tag-status-display">
                                        {t('Subsidized')}
                                      </span>
                                      : // When the user changes the value to zero.
                                      <span className="ant-tag ant-tag-blue tag-status-display">
                                        {t('Suspended')}
                                      </span>)
                                  :null }
                              </>
                            }
                            { (programmatic === "programmatic" && !isPaymentStatusApproved ) &&
                              <div style={{marginBottom: "10px"}}>
                                  {t("Investment")}
                                <Switch
                                  size="small"
                                  checked={isFixedBudgetDisplay}
                                  style={{marginLeft:"5px", marginRight:"5px"}}
                                  onChange={() => fixedBudgetInDisplay([display], isFixedBudgetDisplay)}
                                  />
                                  <Tooltip title={ getTooltipForFixedBudget("display", isFixedBudgetDisplay)}>
                                    <i
                                      className={ isFixedBudgetDisplay ? "fa fa-lock" : "fa fa-unlock"}
                                      aria-hidden="true"
                                      style={{ top:"3px", color: "gray", cursor: "pointer", fontSize: "large", marginRight: true? "5px": "0px"}}
                                    ></i>
                                  </Tooltip>
                              </div>
                            }
                            <div style={{textAlign: "center", marginTop: "5px"}}>
                              <Tooltip title={t('Add contents on the screen')}>
                                  <Button
                                      className='buttonActionsDisplayGroup'
                                      shape="circle"
                                      onClick={(e) => {
                                          handleModalUpdateContent(display);
                                      }}
                                  >
                                      <Icon type="plus" />
                                  </Button>
                              </Tooltip>
                            <Tooltip title={t('Set up rules in this display')}>
                              <Button
                                shape="circle"
                                className='buttonActionsDisplayGroup'
                                onClick={() => showModalDisplayRules(display)}>
                                <Icon type="control" />
                              </Button>
                            </Tooltip>
                            { (((editCampaignActive && currentCampaign.status != 'ready') || !editCampaignActive) && !isPaymentStatusApproved ) &&
                              <Tooltip placement="top" title={displaysCollection.length < 2 ? t('To delete need at least two displays in the campaign') : t('Remove screen')}>
                                <Button
                                  shape="circle"
                                  className='buttonActionsDisplayGroup'
                                  disabled={group.displays.length < 2}
                                  onClick={() => showModalDeleteRelation('deleteDisplay', display.id)}
                                >
                                  <Icon type="close" />
                                </Button>
                              </Tooltip>
                            }
                            <Tooltip title={ !display.fill_screen  ? t('Expand ad') : t('Return original resolution')}>
                              <Button
                                className='buttonActionsDisplayGroup'
                                shape="circle"
                                onClick={() => {
                                  displayToggleFillScreen(display)
                                }}
                              >
                                { !display.fill_screen ? (<Icon type="fullscreen"/> ) : ( <Icon type="fullscreen-exit"/> )}
                              </Button>
                            </Tooltip>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Modal
                        title={t("Screen detail")}
                        visible={showScreenDetail && displayModalInfo.id === display.id}
                        onCancel={() => handleShowScreenDetail("close")}
                        footer={[<Button key="back" onClick={() => handleShowScreenDetail("close")}>OK</Button>]}
                        width={modalWidth}
                        style={{ top: "10px" }}
                      >
                        <ScreenContainer
                          display={displayModalInfo}
                          screenDetail={showScreenDetail}
                          popup={true}
                          defaultTab={"info"}
                          editCart={true}
                          closeModal={() => handleShowScreenDetail("close")}
                        />
                      </Modal>
                      {(displayModalUpdateContent && displayModalUpdateContent.id === display.id) &&
                        <ModalEditCampaignManagerComponent
                          visible={displayModalUpdateContent}
                          groupName={display.name}
                          onCancel={() => handleModalUpdateContent(null)}
                          groupedDisplays={[displayModalUpdateContent]}
                          applyRelationOnScreens={applyRelationOnScreens}
                          isPaymentStatusApproved={isPaymentStatusApproved}
                        />
                      }
                    </div>
                  )})}
              </Panel>
            </Collapse>
            { selectGroupDisplayRules === group.key &&
              <Modal
                title={t("Rules in campaign")}
                visible={ selectGroupDisplayRules == group.key }
                footer={[
                  <Button key="ok" type="primary" onClick={() => showModalGroupDisplayRules()}>
                    {t("Close")}
                  </Button>,
                ]}
                onCancel={() => showModalGroupDisplayRules()}
                width={800}

              >
                <RulesContainer
                  key={group.key}
                  typeModal="groupDisplay"
                  typeName={ group.key }
                  listContentDisplay={ getContentDisplayByScreenGroup(group.displays) }
                />
              </Modal>
            }
            { indexGroup === index &&
              <ModalEditCampaignManagerComponent
                visible={indexGroup === index}
                groupName={group.key}
                onCancel={() => setIndexGroup(null)}
                groupedDisplays={group.displays}
                applyRelationOnScreens={applyRelationOnScreens}
                isPaymentStatusApproved={isPaymentStatusApproved}
              />
            }
          </Col>
        ))}
        {relationToDelete && (
          <Modal
            title={relationToDelete.title}
            visible={!!relationToDelete}
            onOk={() => removeRelationFromDisplayView(relationToDelete)}
            onCancel={() => showModalDeleteRelation()}
            okText={t('Remove')}
            cancelText={t('Cancel')}
          >
            <p style={{margin: "20px"}}>{relationToDelete.description}</p>
          </Modal>
        )}
      </Row>
    </ScreensGroupHolder>
  );
};

export default ScreensGroupComponent;