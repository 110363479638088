import styled from "styled-components";

const CampaignGroupFilterHolder = styled.div`
  .groupFilter {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  .contentGroupFilter {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  .groupFilterButton {
    margin-bottom: 10px;
  }
`;

export default CampaignGroupFilterHolder;