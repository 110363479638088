import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import planner from "../images/planner.webp";
import ModalInfoNewViewHolder from "./Styles/ModalInfoNewViewStyle";

const ModalInfoNewViewComponent = ({ visible, onCancel, showMainModal = true }) => {
  const { t } = useTranslation();

  // State to control the visibility of the video modal
  const [isVideoModalVisible, setVideoModalVisible] = useState( false );
  // State to store the iframe src (YouTube video URL)
  const [videoSrc, setVideoSrc] = useState("");

  // Function to open the video modal and start the video
  const showVideoModal = () => {
    setVideoSrc("https://www.youtube.com/embed/93qj3b9HlXM?autoplay=1");
    setVideoModalVisible(true);
  };

  // Function to close the video modal and stop the video
  const handleVideoModalCancel = () => {
    onCancel();
    setTimeout(() => {
      setVideoModalVisible(false);
      setVideoSrc("");
    }, 100);
  };

  // Function to close the main modal
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    if (!showMainModal && visible ) {
      showVideoModal();
    }
  }, [visible]);

  return (
    <>
      {/* Main modal (conditionally rendered based on showMainModal) */}
      {showMainModal && (
        <Modal visible={visible} onCancel={handleClose} footer={null} width={800}>
          <ModalInfoNewViewHolder>
            <div className="container">
              <Row gutter={[16, 16]} align="middle">
                <Col xs={24} sm={10}>
                  <img src={planner} alt="Planner" className="image" />
                </Col>
                <Col xs={24} sm={14}>
                  <p className="gradient-title">
                    <span style={{ fontSize: "40px" }}>{t("DISCOVER")}</span><br />
                    <span style={{ fontSize: "large" }}>{t("the new way to")}</span><br />
                    <span style={{ fontSize: "xx-large" }}>{t("launch your")} </span>
                    <span style={{ fontSize: "40px" }}>{t("CAMPAIGN!")}</span>
                  </p>
                  <ul className="list">
                    <li>{t("Faster")}</li>
                    <li>{t("Smarter")}</li>
                    <li>{t("Easier to use")}</li>
                  </ul>
                  <p className="subtitle">
                    <span>{t("TRY THE NEW")}</span><br />
                    <span>{t("VERSION NOW!")}</span>
                  </p>

                  <div className="button-container">
                    <Button className="guide-button" onClick={showVideoModal}>
                      {t("View guide")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalInfoNewViewHolder>
        </Modal>
      )}
      <ModalInfoNewViewHolder>
        {/* Video modal */}
        <Modal
          visible={isVideoModalVisible}
          onCancel={handleVideoModalCancel}
          footer={null}
          width={800}
          bodyStyle={{ padding: 0, background: 'transparent' }}
          style={{
            padding: 0,
            borderRadius: 0,
            background: 'transparent',
            boxShadow: 'none',
          }}
          maskStyle={{ background: 'transparent' }} 
        >
          {/* Only render iframe if videoSrc is set */}
          {videoSrc && (
            <iframe
              width="100%"
              height="500"
              src={videoSrc}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Guide Video"
            />
          )}
        </Modal>
      </ModalInfoNewViewHolder>
    </>
  );
};

export default ModalInfoNewViewComponent;




