import styled from "styled-components";

const AudienceImpactsHolder = styled.div`
  .screenCardAudit {
    border-radius: 8px;
    border: 1px solid var(--MAINCOLOR-600, #3996F3);
    background-color: transparent;
    margin-bottom: 5px;
    margin-top: 5px;
    color: var(--MAINCOLOR-600, #3996F3);
  }
`;

export default AudienceImpactsHolder;
