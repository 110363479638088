import React from 'react';
import { Row, Col } from "antd";
import { useTranslation } from 'react-i18next';
import CardTotalSpotsHolder from './Styles/cardTotalSpotsStyle';

const CardTotalSpotsComponent = (props) => {
  const { t } = useTranslation();
  const { stepsCurrent, programmatic, customContents } = props;

  const calculateTotalPromiseShow = () => {
    if (!customContents || !Array.isArray(customContents)) return 0;
    return customContents.reduce((total, item) => total + (item.promised_shows || 0), 0);
  };

  return (
    <CardTotalSpotsHolder hasRedistribute={programmatic === "programmatic" && stepsCurrent !== 0}>
      <div className="cardGeneral">
        <Row justify="center">
          <Col>
            <div>
              <div className='infoTotalSpots'>{t("Total spots")}</div>
              <div className='totalSpotsContainer'>
                <p className='totalSpots'>{calculateTotalPromiseShow()}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CardTotalSpotsHolder>
  );
};

export default CardTotalSpotsComponent;