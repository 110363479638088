import styled from "styled-components";
import Colors from '../../themes/Colors'

const RulesComponentHolder = styled.div`
    .cardRules {
        border-radius: 10px;
        margin-bottom: 10px;
        margin: 15px;
        margin-left: 25;
        margin-right: 25;
        padding-right: 25px;
        padding-left: 25px;
    }
    .ant-card-body {
        padding: 0px;
    }
    .ant-modal-body {
        margin: 20px;
    }
    .container {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .deleteRule {
        display: flex;
        flex-direction: row-reverse;
    }
    .datePickerField {
        width: 90%;
    }
    .datePickerFieldCreated{
        width: 90%;
    }
    /* Text when is disabled */
    .datePickerFieldCreated .ant-input-disabled {
        color: #1d1919ab;
    }
    .timeLabelCreated {
        width: 7%;
        margin-left: 20px;
    }
    .timeFieldCreated .ant-time-picker-input[disabled] {
        color: #1d1919ab;
    }
    .timeLabelCreated { 
        margin-left: 0px;
    }
    .disabledContent {
        pointer-events: none;
        margin-top: 15px;
    }
    .nameLabel {
        width: 10%;
    }
    .nameField {
        width: 60%;
        margin-right: 20px;
    }
    .alertMsg{
        margin-top: 5px;
        margin-bottom: 5px;
        color: #f5222d;
    }
    .buttonContainer {
        margin-bottom: 20px;
        text-align: end;
        padding-top: 10px;
    }
    .divider {
        display: block;
        min-width: 50% !important;
        height: 2px;
        margin-bottom: 10px;
    }
    .title {
        color: #1213149c;
        padding-top: 15px;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        text-align: left;
    }
    .titleName {
        color: #1213149c;
        padding-top: 1px;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        margin-bottom: 0px;
    }
    .contentNameRule {
      color: var(--primary);
      font-size: medium;
    }
    .containerButtonRulesCreated {
        margin-bottom: 10px;
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }

    .titleApply {
        margin: 0;
        color: #4C4C4C;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        word-wrap: break-word;
        margin-left: 18px;
        margin-bottom: 5px;
    }
    .primaryCircle {
        width: 22px;
        height: 22px;
        background-color: ${Colors.primary};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        color: white;
        margin-bottom: 4px;
        margin-right: 4px;
    }
    .versionInRules {
        display: flex;
    }
    .titleRuleList {
        color: #4C4C4C;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        word-wrap: break-word;
        margin-bottom: 5px;
    }
    .textRule {
        color: #23CBF2;
        font-size: 13px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .textInfoRule {
        color: #9E9E9E;
        margin: 0px 0px 0px 0px;
        font-size: 12px;
    }
    .textInfoRuleWithRules {
        color: #23CBF2;
        margin: 0px 0px 0px 0px;
        font-size: 12px;
    }
    .rulesContainer {
        display: flex;
        flex-wrap: wrap;
        max-width: 300px;
    }

    .nameRules {
        border: 1px solid var(--SECONDARY-SECONDARY, #888888);
        color: var(--SECONDARY-SECONDARY, #23CBF2);
        padding: 2px 8px 2px 8px;
        gap: 13px;
        border-radius: 8px;
        width: fit-content;
        margin-right: 15px;
        margin-left: 5px;
        margin-bottom: 10px;
        font-size: small;
        word-wrap: break-word;
        white-space: normal;
    }
    .nameTypeRules {
        border: 1px solid rgb(66 66 66 / 62%);
        padding: 2px 9px 2px 9px;
        gap: 13px;
        border-radius: 8px;
        width: fit-content;
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 10px;
        font-size: 12px;
        white-space: nowrap;
    }
    .contentMessage {
        background: #FAFAFA;
        width: 95%;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        margin-bottom: 15px;
    }
    .contentMessage p {
        margin-bottom: 0px;
        font-size: 18px;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
        color: #888888;
    }
    .iconCheckRules {
        margin-left: 5px;
        font-size: 22px;
        color: var(--SECONDARY-SECONDARY,#23CBF2)
    }
    ${'' /* .contentNameRule {
        background-color: var(--primary);
        color: aliceblue;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 10px;
        display: inline;
    } */}
`;

export default RulesComponentHolder;