import { connect } from 'react-redux'
import DisplayActions from '../redux/DisplayRedux'
import ScreensGroupFilterComponent from "../components/ScreensGroupFilterComponent"

const mapStateToProps = ({ display, userAccount }) => ({
  managerDisplayFilter: display.managerDisplayFilter,
  managerDisplaySelector: display.managerDisplaySelector,
  disabledManagerDisplayFilter: display.disabledManagerDisplayFilter,
  isSuperUser: userAccount.isSuperUser,
  customContents: display.customContents,
  cart: display.cart,
  realAmount: display.realAmount,
  isPaymentStatusApproved: display.isPaymentStatusApproved,
  programmatic: display.programmatic,
  display: display.display
})

const mapStateToDispatch = (dispatch) => ({
  disableManagerDisplayFilter: (disabledManagerDisplayFilter) => dispatch(DisplayActions.disableManagerDisplayFilter(disabledManagerDisplayFilter)),
  setManagerDisplayFilter: (nameFilter) => dispatch(DisplayActions.setManagerDisplayFilter(nameFilter)),
  setManagerDisplaySelector: (nameFilter) => dispatch(DisplayActions.setManagerDisplaySelector(nameFilter)),
  updateCustomContents: (customContents) => dispatch(DisplayActions.updateCustomContents(customContents))
})

export default connect(mapStateToProps, mapStateToDispatch)(ScreensGroupFilterComponent)