
import React from 'react';
import { Row, Col } from "antd";
import { useTranslation } from 'react-i18next';
import CardClientBrandHolder from './Styles/cardClientBrandStyle';


const CardClientBrandComponent = (props) => {
  const { t } = useTranslation();
  const { programmatic, stepsCurrent, client, brand } = props;

  return (
    <CardClientBrandHolder hasRedistribute={programmatic === "programmatic" && stepsCurrent !== 0}>
      <div className="cardGeneral">
        <Row justify="center">
          <Col>
            <div>
              <div className='infoDate'>{t("Client")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{client.name}</p>
              </div>
              <div className='infoDate'>{t("Mark")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{brand.name}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CardClientBrandHolder>
  );
};

export default CardClientBrandComponent;