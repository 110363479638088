import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardAdsInfoHolder = styled.div`
  .cardGeneral {
    position: fixed;
    padding-left: 10px;
    padding-bottom: 10px;
    z-index: 996;
    right: 10px;
    height: auto;
    line-height: 30px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 11%;

    @media only screen and (max-width: 1200px) {
      position: absolute;
      width: fit-content;
      top: 130px;
    }
    @media only screen and (max-width: 500px) {
      position: absolute;
      width: fit-content;
      top: 130px;
    }

    @media only screen and (min-width: 576px) {
      top: calc(515px + 70px);
    }

    @media only screen and (min-width: 1000px) {
      top: calc(440px + 12px);
    }

    @media only screen and (max-width: 575px) {
      position: relative;
      margin-left: 10px;
      margin-top: 10px;
    }
    @media only screen and (max-width: 1400px) {
      .impacts-container {
        line-height: 20px;
      }
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .infoDate {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .brandClientNameContainer {
    background-color: white;
    border-radius: 8px;
    padding: 2px;
    margin-right: 10px;
   }
   .brandClientName {
    margin: 0;
    color: ${Colors.primary};
    font-weight: 500;
   }
`;

export default CardAdsInfoHolder;