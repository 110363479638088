import React, { memo, useEffect, useMemo, useState } from "react";
import { Button, Card, Icon, Row, Skeleton, Tooltip } from "antd";
import { convertImage } from "../services/contents";
import { useTranslation } from 'react-i18next';
import OptionModal from "./OptionModal";
import Rectangle from "react-rectangle";
import HideWhenCampaignActive from "../containers/HideWhenCampaignActiveContainer";
import { set } from "lodash";
import ContentSpecificationAlertComponent from "./ContentSpecificationAlertComponent";

const AspectRatioScreenContent = ({ children, className, relation }) => {
  const isTallerOrSquare = useMemo(() => {
    if (!relation.resolution_width || !relation.resolution_height) return false;

    const aspectRatio = relation.resolution_height / relation.resolution_width;

    return aspectRatio >= 1;
  }, [relation.resolution_width, relation.resolution_height]);

  return (
    <div
      className="contentRectangle"
      style={{ flexDirection: isTallerOrSquare ? "row" : "column" }} >
      <Rectangle
        className={`${className || "rectangle"}`}
        aspectRatio={[
          relation.resolution_width,
          relation.resolution_height
        ]}
        style={{
          height: isTallerOrSquare ? "100%" : null,
          width: !isTallerOrSquare ? "100%" : null,
          aspectRatio: `${relation.resolution_width} / ${relation.resolution_height}`,
        }}
      >
        {children ? (
          <div className="alignImage">
            {children}
          </div>
        ) : null}
      </Rectangle>
    </div>
  )
};

const RequiredResolutionsCard = ({
  removeRelationsOnCampaign,
  customContents,
  setListResolutionWithContent,
  listResolutionWithContent,
  relation,
  loading,
  content = null,
  className,
  style,
  isWarning,
  onClickImage,
  diseabledDeleteButton,
  setListOfContentsInCampaign,
  listOfContentsInCampaign,
  updateImpressionsDisplay,
  groupedDisplays
}) => {

  const { t } = useTranslation();
  const [countContentsDisplay, setContentsDisplay] = useState(0);
  const [selectedContent, setSelectedContent] = useState(null);

  const otherListRelations = useMemo(() => {
    return getListNotBelongToListResolution();
  }, [listResolutionWithContent]);

  // Create getListNotBelongToListResolution
  function getListNotBelongToListResolution() {
    if(!listResolutionWithContent) return [];
    const listNotBelongToListResolution = [];
    listResolutionWithContent.forEach(group => {
      const relationAux = (relation.resolution_height != group.resolution_height && relation.resolution_width != group.resolution_width) ? [group]: [];
      if (relationAux.length > 0) {
        listNotBelongToListResolution.push(group);
      }
    });

    return listNotBelongToListResolution;
  }

  const handleDelete = () => {
    let newRelation = { ...relation };
    // Delete of customContents if the content is in the relation
    if (customContents && customContents.length > 0) {
      newRelation.contentsDisplays.forEach(newCustomContent => {
        if (selectedContent !== newCustomContent.contentId) return;

        const contentDisplayForUpdateImpression = customContents.filter(
          customItem => customItem.displayId === newCustomContent.displayId
        );

        const deltedCustomContent = contentDisplayForUpdateImpression.find(
          customItem => customItem.content.id === newCustomContent.contentId
        );

        if (deltedCustomContent) {
          removeRelationsOnCampaign(deltedCustomContent.displayId, deltedCustomContent.content.id)
          const countCustomesContent = contentDisplayForUpdateImpression.length -1;
          let impressions = Math.trunc(deltedCustomContent.promised_shows / countCustomesContent)
          contentDisplayForUpdateImpression.forEach(item => {
              let newPromisedShows = item.promised_shows + impressions;
              updateImpressionsDisplay(item.displayId, item.relationId, newPromisedShows);
          });
        }
      });
      setSelectedContent(null);
    }

    // Delete of listResolutionWithContent
    if(groupedDisplays) {
      // When is from modal, not deleted all displays of the resolution because is only for one or group of displays
      const listDisplay = groupedDisplays.map(display => display.id);
      newRelation.contentsDisplays = newRelation.contentsDisplays.filter(item => {
          if(!(item.contentId === selectedContent && listDisplay[0] === item.displayId)) {
            return item;
          }
      });
    } else {
      newRelation.contentsDisplays = newRelation.contentsDisplays.filter(item => item.contentId !== selectedContent);
    }

    newRelation.contents = newRelation.contents.filter(item => item.id !== selectedContent);

    const newListResolutionWithContent = listResolutionWithContent.map(item => {
      if (item.resolution_height === newRelation.resolution_height && item.resolution_width === newRelation.resolution_width) {
        return newRelation;
      }
      return item;
    });

    const isInOtherRelation = checkIfContentIsInRelation();
    if (!isInOtherRelation) {
      const listOfContentsInCampaignAux = listOfContentsInCampaign.filter(item => item.id !== selectedContent) || [];
      setListOfContentsInCampaign(listOfContentsInCampaignAux);
    }
    setListResolutionWithContent(newListResolutionWithContent);
  };

  const titleForDeleteContent = () => {
    if (!selectedContent) return t('The ad will be detached from the group of screens of this resolution and will no longer be used in the campaign. You can add it again later.');
    const isInOtherRelation = checkIfContentIsInRelation();
    if (!isInOtherRelation) {
      return t('Are you sure you want to delete this relationship?')
    }
    return t('Are you sure you want to delete this relationship?. The content will be removed from the campaign because it will not belong to any relationship.');
  };

  const checkIfContentIsInRelation = () => {

    let isInOtherRelation = false;
    if(groupedDisplays && groupedDisplays.length === 1) { //When delete from one display
      console.log(listResolutionWithContent)
      isInOtherRelation = listResolutionWithContent.find(r =>
        r.contentsDisplays.some(contentDisplay => contentDisplay.contentId === selectedContent && contentDisplay.displayId != groupedDisplays[0].id)
      );
    } else {
      isInOtherRelation = otherListRelations.find(r =>
        r.contentsDisplays.some(contentDisplay => contentDisplay.contentId === selectedContent)
      );
    }

    return isInOtherRelation ? true: false;
  }

  function countContentDisplays () {
    if (relation && relation.contentsDisplays) {
      const countAux = content ?
        new Set(
          relation.contentsDisplays
            .filter(item => item.contentId === content.id)
            .map(item => item.displayId)
        ).size
      : 0;

      setContentsDisplay(countAux);
    }
  }


  useEffect(() => {
    countContentDisplays();
  }, [relation, customContents]);

  if (countContentsDisplay === 0 && content) {
    return null
  }

  return (
    <div
      className={`card-wrapper ${className}`}
      style={style}
    >
      {loading ? (
        <Skeleton
          className="skeletonRequiredCard"
          active
          paragraph={{ rows: 1, width: 156 }}
        />
      ) : (
        <>
          <Card className={`cardContent ${isWarning ? "border-orange" : ""}`}>
            <Row type="flex" style={{ flexDirection: "column", gap: 10 }} justify="space-between">
              <span className="textCard contentTitleRelation">
                <p className="textCard centerCard" style={{ fontSize: "12px", marginRight: "4px" }}>
                  {relation.resolution_width} x {relation.resolution_height}
                </p>
                {isWarning ? (
                  <Icon type="warning" style={{ marginTop: "5px", color: "#FAAD14" }} />
                ) : (
                  <Icon type="desktop" style={{ marginTop: "5px", color: content ? '#7CB354' : '#000000' }} />
                )}
                <div style={{padding:"1px"}}>
                  <ContentSpecificationAlertComponent
                    listResolutionWithContent= {[relation]}
                    styleCustom={{marginBottom : "5px", color: '#f44336'}}
                  />
                </div>
                <Tooltip title={t('Displays added in this relation')}>
                  <div className={countContentsDisplay ? "greenCircle" : "circle"} >
                    { countContentsDisplay || relation.displays.length }
                  </div>
                </Tooltip>
              </span>
              {!content ?
                <AspectRatioScreenContent
                  className="rectangleNoContent"
                  relation={relation}
                />
                :
                <>
                  <AspectRatioScreenContent
                    relation={relation}
                  >
                    <img
                      src={convertImage(content.file_thumb)}
                      className="contentRectangleCardImage"
                      onClick={onClickImage}
                      alt=""
                    />
                  </AspectRatioScreenContent>
                  <div className='contentCardContent contentButtonCardResolution'>
                    <span className={"primaryCircle"} style={{ marginTop: "4px" }}>
                      {content.content_version_name || "V1"}
                    </span>
                    <HideWhenCampaignActive
                      contentId={content.id}
                      displaysId={ relation.displays }>
                      <Tooltip title={diseabledDeleteButton ?
                        t("It's the last relation on a screen, to delete it, its contents should be reorganized"):
                        t('Unlink ad from this screen group.')}>
                          <Button
                            shape="circle"
                            className={!diseabledDeleteButton ? "icon-card cardRed":"" }
                            disabled={diseabledDeleteButton}
                            onClick={() => setSelectedContent(content.id)}
                          >
                            <Icon type="close" />
                          </Button>
                      </Tooltip>
                    </HideWhenCampaignActive>
                  </div>
                </>
              }
            </Row>
          </Card>

          {listResolutionWithContent && (
            <OptionModal
              title={t('Atention!')}
              body={titleForDeleteContent()}
              visible={!!selectedContent}
              okText={t('Delete')}
              cancelText={t('Cancel')}
              onOk={handleDelete}
              onCancel={() => setSelectedContent(null)}
            />
          )}
        </>
      )}
    </div>
  );
}

export default memo(RequiredResolutionsCard);
