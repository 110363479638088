import React, { useEffect, useMemo, useState, version} from 'react';
import { Checkbox,
         DatePicker,
         Input,
         Card,
         TimePicker,
         Button,
         Icon,
         Divider,
         Tooltip,
         Collapse,
         Form,
         Col,
         Row } from "antd";
import moment from 'moment';
import RulesComponentHolder from "./Styles/rulesComponentStyle";
import { useTranslation } from 'react-i18next';
import { cloneDeep, remove, set } from 'lodash';
import VersionEnum from '../constants/VersionEnum';
import DaysEnum from '../constants/DaysEnum';
import { withTranslation } from 'react-i18next';

const { Panel } = Collapse;
const customPanelStyle = {
  background: '#FFFFFF',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
  boxShadow : "rgba(0, 0, 0, 0.25) 1px 2px 4px 0px"
};

const customPanelStyleContent = {
  borderTopWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
  marginLeft: "15px",
  marginRight: "15px",
}
const keysArrayVersion = VersionEnum.map(item => item.key);

// Modal used in different parts of the application
// Depending on the modal type (typeModal), a specific view will be displayed:
// - For typeModal "display": Modal for displaying rules on screens.
// - For typeModal "groupDisplay": Modal for displaying rules for screen groups.
// - For typeModal "contentDisplay": Modal for displaying rules for content displays.
// - For typeModal "create": Modal for creating new rules.
// - For typeModal "campaign": Modal for displaying rules for campaign.

const RulesComponent = (props) => {

    const {t}  = useTranslation();
    const dateFormat = props.i18n.language === 'es' ? 'DD/MM/YYYY':'MM/DD/YYY'
    const { date_from,
            date_to,
            libraryRules,
            setLibraryRules,
            typeModal,
            listContentDisplay, //Is the list of contentDisplays that will be affected by the rules
            updateRulesRelationScreen,
            editCampaignActive,
            customContents,
            typeName } = props;

    const { RangePicker } = DatePicker;
    const [listCreatedRules, setListCreatedRules] = useState([]);
    const [libraryRulesView , setLibraryRulesView] = useState([]);
    const [validateName, setValidateName] = useState(null);
    const [activeKey, setActiveKey] = useState([]);

    const versionApplied = useMemo(() => {
        return VersionEnum.
            filter(item => listContentDisplay.
            some(contentDisplay => contentDisplay.content.content_version_name === item.key))
            .map(item => item.key);
     }, [listContentDisplay]);

    const handleCollapseChange = (key) => {
        setActiveKey(key);
    };

    const [configRules, setConfigRules] = useState({
        name: "Rule" + '-' + 0,
        id: 0,
        content_version_name: versionApplied
    });

    const updateConfigRules = () => {
        const nextId = libraryRules.reduce((max, rule) => (rule.configRules.id > max ? rule.configRules.id : max), 0) + 1;
        const newConfig = {
            name: "Rule" + '-' + nextId,
            id: nextId,
            content_version_name: versionApplied
        }
        // Reset the new rules
        setConfigRules(newConfig);
    };

    // Is the default rule that have 3 types of conditions
    const defaultGroupRules = [
        { conditions: [
            {
                type: "hour",
                from: "00:00",
                to: "23:59",
            },
            {
                type: "day",
                value: [1,2,3,4,5,6,0]
            },
            {
                type: "date",
                from_date: date_from,
                to_date: date_to
            }
    ]}]

    const isDayChecked = (day, rule) => {
        const condition = rule && rule.conditions.find(condition => condition.type === 'day');
        return condition && condition.value.includes(day);
    };

    const addRule = () => {
        setListCreatedRules([...listCreatedRules, defaultGroupRules[0]]);
    };

    const updateGrupRules = (condition) => {
        setListCreatedRules(condition);
    }

    const removeRule = (indexToRemove, relation) => {
        let newRules = cloneDeep(relation);
        const updatedRules = newRules.filter((rule, index) => index !== indexToRemove);
        updateGrupRules(updatedRules);
    };

    const validateForm = () => {
        setValidateName(null)
        if (configRules.name === "") {
            setValidateName(t("The name of the rule is required"))
            return false
        }

        let validateName = libraryRules.find(rule => rule.configRules.name === configRules.name)
        if(validateName){
            setValidateName(t("The name of the rule already exists"))
            return false
        }
        return true
    }

    const saveRules = () => {
        if (!validateForm()) return;
        let updatedGroupRules = listCreatedRules.map((rule, index) => ({
            ...rule,
            configRules: configRules
        }));

        // Update the libraryRules with the new rules
        updatedGroupRules = [...updatedGroupRules, ...libraryRules]
        setLibraryRules(updatedGroupRules);
        updateGrupRules(defaultGroupRules);
        setActiveKey([])
        return updatedGroupRules
    };

    const saveRulesAndApply = async () => {
        try {
            let updatedGroupRules = saveRules();
            const versionToApply = configRules.content_version_name
            applyRuleInContentDisplayFromView(updatedGroupRules, updatedGroupRules[0].configRules.id, versionToApply);
            setActiveKey([])
        } catch (error) {
            console.error("Error saving rules and applying them:", error);
        }
    };

    // Format the rules to be applied in the contentDisplay
    // @param { libraryRuleView } is rule that want to apply
    const applyRuleInContentDisplayFromView = (updatedGroupRules, idRule, versionViewSelectedRule) => {
        let rulesToApply;
        let deletedVersion;
        const versionUnselectedRule = versionApplied.filter(version => !versionViewSelectedRule.includes(version));

        // Search rule with same format in libraryRules
        rulesToApply = updatedGroupRules.filter(rule => {
            return rule && rule.configRules && idRule === rule.configRules.id;
        });

        /**
         * This filters the versions in `versionUnselectedRule`, keeping only those
         * that are present in all elements of `listContentDisplay` and have the `idRule` applied. 
         * The result is an array of versions that meet both conditions.
         */
        deletedVersion = versionUnselectedRule.filter(version => {
            return listContentDisplay.every(contentDisplay => {
                if (contentDisplay.content.content_version_name === version) {
                    let rules = contentDisplay.rules || [];
                    return rules.some(rule => rule.configRules.id === idRule);
                }
                return true;
            });
        }).map(version => version);

        listContentDisplay.forEach(contentDisplay => {
            let cloneRules = [...(contentDisplay.rules || [])];
            if( deletedVersion.length > 0 && deletedVersion.includes(contentDisplay.content.content_version_name)) {
                cloneRules = cloneRules.filter(rule => rule.configRules.id !== idRule);
            }
            if (typeModal=== "contentDisplay" || versionViewSelectedRule.includes(contentDisplay.content.content_version_name)) {
                if(cloneRules.filter(rule => rule.configRules.id === idRule).length === 0){
                    rulesToApply.forEach(rule => {
                        cloneRules.push(rule);
                    });
                }
            }
            if(cloneRules === contentDisplay.rules ) return;
            updateRulesRelationScreen(contentDisplay.displayId, contentDisplay.relationId, cloneRules);
        });

        // Reset changedValues for change button
        let ruleUpdate = libraryRulesView.find(rule => rule.id === idRule);
        ruleUpdate = {...ruleUpdate, changedValues: false}
        setLibraryRulesView(libraryRulesView.map(rule => rule.id === idRule ? ruleUpdate : rule));
    };

    // @param { libraryRuleView } is rule that want to delete
    // @param { version } is the version of the rule that want to delete
    const removeRuleInContentDisplay = (libraryRuleView, version=null) => {
        // Find rules in contentDisplay that match the provided relationId
        listContentDisplay.map((contentDisplay, index) => {
            let newContent = cloneDeep(contentDisplay.rules) || [];
            let newRules
            if (!version) {
                // Filter out rules matching the relationId
                newRules = newContent.filter(rule => rule.configRules.id !== libraryRuleView.id);
            } else {
                newRules = newContent.filter(rule => rule.configRules.id !== libraryRuleView.id && !rule.configRules.content_version_name.includes(version));
            }

            // Update the contentDisplay with the modified rules
            updateRulesRelationScreen(contentDisplay.displayId, contentDisplay.relationId, newRules);
        });
    };

    const hasRuleSomeDisplay = (checkRule) => {
        return customContents.some(contentDisplay => {
            let rules = contentDisplay.rules || [];
            return rules.some(rule => rule.configRules.id === checkRule.id);
        });
    };

    const changeVersion = (e) => {
        let versions
        if (e.target.checked) {
            versions = [...configRules.content_version_name, e.target.value];
        } else {
            versions = configRules.content_version_name.filter(v => v !== e.target.value)
        }
        setConfigRules({...configRules, content_version_name: versions});
    }

    const loaderVersion = (e, ruleInview) =>{
        let versions;
        if (e.target.checked) {
            versions = [...ruleInview.content_version_name, e.target.value];
        } else {
            versions = ruleInview.content_version_name.filter(v => v !== e.target.value)
        }
        const ruleUpdate = {...ruleInview, content_version_name: versions, changedValues: true}
        setLibraryRulesView(libraryRulesView.map(rule => rule.id === ruleInview.id ? ruleUpdate : rule));
    }

    const changeDay = (e, index, relation) => {
        let newRules = cloneDeep(relation);
        let arrayDays = {
            "type":"day",
            "value":[]
        }
        if (newRules[index].conditions.find(condition => condition.type === 'day')) {
            let daysRules = newRules[index].conditions.find(condition => condition.type === 'day')
            for (var i = 0; i < daysRules.value.length; i++) {
                arrayDays.value.push(daysRules.value[i])
            }
        }

        // Add or delete rule, depending on the checkbox
        if (e.target.checked) {
            const parsedValue = parseInt(e.target.value);
            if (Number.isInteger(parsedValue) && 0 <= parsedValue && parsedValue <= 6) {
                arrayDays.value.push(parsedValue);
            }
        } else {
            const removeValue = parseInt(e.target.value);
            remove(arrayDays.value, function(day) {
                return day === removeValue;
            });
        }

        if (newRules[index].conditions.find(condition => condition.type === 'day')) {
            remove(newRules[index].conditions, function(condition) {
                return condition.type === 'day';
            });

            newRules[index].conditions.push(arrayDays)
        } else {
            newRules[index].conditions.push(this.state.arrayDays)
        }
        updateGrupRules(newRules)
    };

    const changeDate = (dateString, index, relation) => {
        const newRules = cloneDeep(relation);
        if (dateString[0]==="" && dateString[1]==="" || dateString.length === 0){
            dateString[0] = date_from
            dateString[1] = date_to
        }else{
            dateString[0] = moment(dateString[0], dateFormat).format("YYYY-MM-DD")
            dateString[1] = moment(dateString[1], dateFormat).format("YYYY-MM-DD")
        }

        const arrayDate = {"type":"date", "from_date": dateString[0], "to_date": dateString[1]}

        if (newRules[index].conditions.find(condition => condition.type === 'date')) {
            remove(newRules[index].conditions, function(condition) {
                return condition.type === 'date';
            });
            newRules[index].conditions.push(arrayDate)
        } else {
            newRules[index].conditions.push(arrayDate)
        }
        updateGrupRules(newRules)
    };

    const changeStartTime = (time, timeString, index, relation) => {
        let newRules = cloneDeep(relation);
        let arrayTime= {
                "type":"hour",
                // "from": this.props.display.working_hours[0] ? this.props.display.working_hours[0].start_time : "00:00",
                // "to": this.props.display.working_hours[0] ? this.props.display.working_hours[0].end_time : "23:59",
                "from": "00:00",
                "to": "23:59",
            }
        if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
            arrayTime = newRules[index].conditions.find(condition => condition.type === 'hour')
        }

        arrayTime.from = timeString;
        if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
            remove(newRules[index].conditions, function(condition) {
                return condition.type === 'hour';
            });
            newRules[index].conditions.push(arrayTime)
        } else {
            newRules[index].conditions.push(arrayTime)
        }
        updateGrupRules(newRules)
    };

    const changeEndTime = (time, timeString, index, relation) => {
        let newRules = cloneDeep(relation);
        let arrayTime = {
                "type":"hour",
                // "from": this.props.display.working_hours[0] ? this.props.display.working_hours[0].start_time : "00:00",
                // "to": this.props.display.working_hours[0] ? this.props.display.working_hours[0].end_time : "23:59",
                "from": "00:00",
                "to": "23:59",
            }

        if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
            arrayTime = newRules[index].conditions.find(condition => condition.type === 'hour')
        }
        arrayTime.to = timeString;
        if (newRules[index].conditions.find(condition => condition.type === 'hour')) {
            remove(newRules[index].conditions, function(condition) {
                return condition.type === 'hour';
            });
            newRules[index].conditions.push(arrayTime)
        } else {
            newRules[index].conditions.push(arrayTime)
        }
        updateGrupRules(newRules)
    };

    const dates = {
      'date_from': moment(date_from, 'YYYY-MM-DD'),
      'date_to': moment(date_to, 'YYYY-MM-DD'),
    }

    // Define the dates depending on the rule
    const getDateValuesFromRule = (rule) => {
        const condition = rule.conditions.find(condition => condition.type === 'date');
        const fromDate = condition && condition.from_date ? moment(condition.from_date, 'YYYY-MM-DD') : dates.date_from;
        const toDate = condition && condition.to_date ? moment(condition.to_date, 'YYYY-MM-DD') : dates.date_to;
        return [fromDate, toDate];
    };

    // Define the days depending on the rule
    const getTimePickerValuesFromRule = (rule, defaultStartTime, type) => {
        const condition = rule.conditions.find(condition => condition.type === 'hour');
        let time = null;
        if(type == "start"){
            time = condition && condition.from ? moment(condition.from, 'HH:mm') : (false ? moment(this.props.display.working_hours[0].start_time, 'HH:mm') : moment(defaultStartTime, 'HH:mm'));
        }
        if(type == "end"){
            time = condition && condition.to ? moment(condition.to, 'HH:mm') : (false ? moment(this.props.display.working_hours[0].end_time, 'HH:mm') : moment(defaultStartTime, 'HH:mm'));
        }
        return time;
    };

    const viewlistRules = (rule, index, rules) => {
        const lengthGroupRules = rules.groupRules.length
        let date = rule.conditions.find(condition => condition.type === 'date');
        date = date
            ? [moment(date.from_date, 'YYYY-MM-DD'), moment(date.to_date, 'YYYY-MM-DD')] 
            : [moment(date_from, 'YYYY-MM-DD'), moment(date_to, 'YYYY-MM-DD')];

        let day = rule.conditions.find(condition => condition.type === 'day');
        day = day ? day.value : defaultGroupRules[0].conditions[1].value;

        let hour = rule.conditions.find(condition => condition.type === 'hour');
        let hour_to = moment ((hour ?  hour.to :  defaultGroupRules[0].conditions[0].to) ,'HH:mm');
        let hour_from = moment ((hour ?  hour.from :  defaultGroupRules[0].conditions[0].from) ,'HH:mm');

        return (
            <>
                <Row gutter={14} className="disabledContent">
                    {/* Dates Section */}
                    <Col span={10}>
                        <div>{t('Dates')}</div>
                        <RangePicker
                            disabled
                            className="datePickerFieldCreated"
                            defaultValue={date}
                            format={dateFormat}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        />
                    </Col>
                    {/* Days of the Week Section */}
                    <Col span={14}>
                        <div className="dayLabel">{t('Days of the week')}</div>
                        <div>
                            {DaysEnum.map(days => (
                                <Checkbox
                                    className='dayCheckedDiseabled'
                                    key={days.value}
                                    value={days.value}
                                    checked={day === 0 ? day.includes(0) : day.includes(days.value)}
                                    disabled={!(day === 0 ? day.includes(0) : day.includes(days.value))}
                                    >
                                        {t(days.label)}
                                </Checkbox>
                            ))}
                        </div>
                    </Col>
                </Row>

                <Row gutter={16} className="disabledContent" style={{ marginTop: "20px" }}>
                    {/* Hours Section */}
                    <Col span={24}>
                        <div className="timeLabelCreated">{t('Hours')}</div>
                        <div style={{ display: "flex", gap: "10px" }}>
                        <TimePicker
                            disabled
                            className='timeFieldCreated'
                            defaultValue={hour_from}
                            format='HH:mm'
                            style={{ marginRight: "15px" }}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        />
                        <TimePicker
                            disabled
                            className='timeFieldCreated'
                            defaultValue={hour_to}
                            format='HH:mm'
                            style={{ marginRight: "10px" }}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        />
                        </div>
                    </Col>
                </Row>
            { (lengthGroupRules > 1 && index < lengthGroupRules - 1) &&
                <Divider className="divider"/>
            }
            </>
        )
    }

    // Check if the rule is applied in the contentDisplay depending on libraryRulesView.content_version_name
    const hasRule = (checkRule) => {
        const versionAppliedInRule = libraryRulesView.find(rule => rule.id === checkRule.id).content_version_name;
        if(!versionAppliedInRule) return false;
        let hasRule = false;
        versionAppliedInRule.forEach(version => {
            const listContentDisplayAux = listContentDisplay.filter(contentDisplay => contentDisplay.content.content_version_name === version);
            if(!listContentDisplayAux) return false;
            hasRule = listContentDisplayAux.every(contentDisplay => {
                const rules = contentDisplay.rules || [];
                return rules.some(rule => rule.configRules.id === checkRule.id);
            });
        });
        return hasRule;
    };

    const hasAppliedRulesInVersion = (ruleId, version) => {
        // Check if the rule is applied in ALL relations for the given version
        const applyInAllRelations =
            listContentDisplay
                .filter(contentDisplay => contentDisplay.content.content_version_name === version)
                .every(contentDisplay => {
                    let rules = contentDisplay.rules || [];
                    return rules.some(rule => rule.configRules.id === ruleId);
                });
        return applyInAllRelations;
    };

    const isRuleAppliedSomewhere = (ruleId) => {
        // Check if the rule is applied in AT LEAST ONE relation
        return listContentDisplay.some(contentDisplay => {
            let rules = contentDisplay.rules || [];
            return rules.some(rule => rule.configRules.id === ruleId);
        });
    };

    const showVersionApplied = (rules) => {
        if (!versionApplied) return false;

        // Versions where the rule is applied in ALL relations
        const versionAppliedAux = versionApplied.filter(version =>
            hasAppliedRulesInVersion(rules.id, version)
        );

        // Check if the rule is applied at least once
        const appliedSomewhere = isRuleAppliedSomewhere(rules.id);

        if (!appliedSomewhere) return <></>;

        const allVersionsLength = versionApplied.length;
        const appliedVersionsLength = versionAppliedAux.length;

        let tooltipText = "";

        if (appliedVersionsLength === allVersionsLength) {
            // Rule applied in ALL relations for ALL versions
            tooltipText = t('ruleAppliedAllVersions', { versions: versionAppliedAux.join(', ') });
        } else if (appliedVersionsLength > 0) {
            // Rule applied in all relations for SOME versions, but not all
            tooltipText = t('ruleAppliedSomeVersions', { versions: versionAppliedAux.join(', ') });
        } else {
            // Rule applied in some relations only
            tooltipText = t('ruleAppliedSomeRelations');
        }

        return (
            <>
                {/* Show version names only if rule is applied in all related contentDisplays */}
                {typeModal !== "contentDisplay" && versionAppliedAux.map((version, index) => (
                    <span key={index} className="primaryCircle">
                        {version}
                    </span>
                ))}
                {/* Show the tooltip with dynamic message */}
                <Tooltip placement="top" title={<span>{tooltipText}</span>}>
                    <Icon type="check-circle" className="iconCheckRules" theme="outlined" />
                </Tooltip>
            </>
        );
    };

    const removeRuleOfList = (idToDelete) => {
        const updatedLibraryRules = libraryRules.filter(rule => rule.configRules.id !== idToDelete);
        setLibraryRules(updatedLibraryRules);

    };

    const formatRules = (libraryRules) => {
      return libraryRules.reduce((acc, rule) => {
            const { id, name } = rule.configRules;
            const versionAppliedAux = versionApplied.reduce((acc, version) => {
                if (hasAppliedRulesInVersion(rule.configRules.id, version)) acc.push(version);
                return acc;
            }, []);

            if (!acc[id]) {
                acc[id] = {
                    id: id,
                    name: name,
                    content_version_name: versionAppliedAux,
                    groupRules: []
                };
            }
            if (rule.configRules.id === id) {
                acc[id].groupRules.push({conditions: [...acc[id].groupRules, ...rule.conditions]});
            }
            return acc;
        }, {});
    }

    // Prepare the array to display the set of rules according to their id,
    // it is necessary because it is not displayed the same as received from the API.
    // Group conditions according to their id.
    useEffect(() => {
        // Create a new array with the rules grouped by their id because libraryRules have in a different formay
        const groupRules = formatRules(libraryRules)
        setLibraryRulesView(Object.values(groupRules).reverse());
    }, [libraryRules, listContentDisplay]);

    useEffect(() => {
        updateConfigRules();
    }, [libraryRules]);

    useEffect(() => {
        addRule();
        if ( libraryRules.length === 0 && editCampaignActive ) {
            let listRules = []
            customContents.forEach(element => {
                element.rules.forEach(rule => {
                    if (!listRules.find( lr => lr.configRules.id === rule.configRules.id)){
                        listRules.push(rule)
                    }
                });
            });
            setLibraryRules(listRules);
        }
        updateConfigRules();
    }, []);

    return (
        <RulesComponentHolder>
            {listCreatedRules.length > 0 &&
                <Card className='cardRules'>
                    <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{marginTop: "0px", marginBottom:"8px"}}>
                        <div style={{marginTop: "0px", marginBottom:"0px"}}>
                            <h2 className='title' style={{marginTop:"5px"}}> {t('Create new rules')}</h2>
                            <h3 className='titleName' style={{marginTop:"5px"}}> {t('Name')}</h3>
                            <div style={{marginTop: "0px", marginBottom:"0px"}}>
                                <Form.Item
                                    validateStatus={!!validateName ? "error" : null }
                                    help={validateName}
                                >
                                    <Input
                                        className="nameField"
                                        placeholder={t('Name of the rule')}
                                        value={configRules.name}
                                        onChange={(e) => setConfigRules({...configRules, name: e.target.value})}
                                        />
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                    {(typeModal != "contentDisplay") &&
                    <>
                    <Col xs={17} sm={17} md={17} lg={17} xl={17} style={{paddingTop:"10px"}}>
                        <div style={{ marginRight:"5px", marginTop:"50px" }}>{t('Version of the ad')}</div>
                        <span style={{ display: "inline-flex", flexWrap: "wrap", gap: "2px", lineHeight: "2", marginBottom: "8px"}}>
                            {versionApplied.
                                map((item , index) => (
                                    <div>
                                        <Checkbox
                                            value={item}
                                            key={index}
                                            checked={configRules.content_version_name.includes(item)}
                                            onChange={(e) => changeVersion(e)}>{item}
                                        </Checkbox>
                                    </div>
                            ))}
                        </span>
                    </Col>
                    </>
                    }
                    <Divider className="divider"/>
                    { listCreatedRules.map((rule, index) => (
                        <>
                            { listCreatedRules.length > 1 &&
                                <div className='deleteRule'>
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <span>{t('Remove rule')}</span>
                                        }>
                                        <Icon type="close" className="closeRuleButton" onClick={() => removeRule(index, listCreatedRules)}/>
                                    </Tooltip>
                                </div>
                            }
                            <div style={{ marginTop: listCreatedRules.length > 1 ? "0px" : "20px" }}>
                            <Row gutter={16}>
                                {/* Dates Section */}
                                <Col span={10}>
                                    <div>{t('Dates')}</div>
                                    <RangePicker
                                        className="datePickerField"
                                        onChange={(dateString) => changeDate(dateString, index, listCreatedRules)}
                                        defaultValue={getDateValuesFromRule(rule)}
                                        value={getDateValuesFromRule(rule)}
                                        format={dateFormat}
                                    />
                                </Col>
                                <Col span={14}>
                                    <div>{t('Days of the week')}</div>
                                    {DaysEnum.map(day => (
                                        <Checkbox
                                        key={day.value}
                                        onChange={(e) => changeDay(e, index, listCreatedRules)}
                                        value={day.value}
                                        checked={isDayChecked(day.value, rule)}
                                        >
                                        {t(day.label)}
                                        </Checkbox>
                                    ))}
                                    {/* Show warning message if no day is selected */}
                                    {rule.conditions.find(condition => condition.type === 'day') &&
                                        rule.conditions.find(condition => condition.type === 'day').value.length < 1 ? (
                                        <p className="alertMsg">{t('You must select at least one day')}</p>
                                        ) : null}
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: "20px" }}>
                                {/* Hours of Function Section */}
                                <Col span={24}>
                                <span className="timeLabel">{t('Hours')}</span>
                                <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                                    <TimePicker
                                    onChange={(time, timeString) => changeStartTime(time, timeString, index, listCreatedRules)}
                                    defaultValue={getTimePickerValuesFromRule(rule, '00:00', 'start')}
                                    value={getTimePickerValuesFromRule(rule, '00:00', 'start')}
                                    format='HH:mm'
                                    style={{ marginRight: 15 }}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    />
                                    <TimePicker
                                    onChange={(time, timeString) => changeEndTime(time, timeString, index, listCreatedRules)}
                                    defaultValue={getTimePickerValuesFromRule(rule, '23:59', 'end')}
                                    value={getTimePickerValuesFromRule(rule, '23:59', 'end')}
                                    format='HH:mm'
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    />
                                </div>
                                </Col>
                            </Row>
                            </div>
                            { index < listCreatedRules.length - 1 &&
                                <Divider className="divider"/>
                            }
                        </>
                    ))}
                    <div className='buttonContainer'>
                        <Button type="primary" style={{marginRight: "10px"}} onClick={()=>addRule()}>
                            <Icon type="plus" />{t("Regla")}
                        </Button>
                        <Button type="primary" onClick={()=>saveRules()}>
                            <Icon type="save" />{t("Save")}
                        </Button>
                        <Button type="primary" style={{marginLeft: "10px"}}  onClick={()=>saveRulesAndApply()}>
                            <Icon type="save" />{t("Save and apply")}
                        </Button>
                    </div>
                </Card>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: "10px"}}>
                <div className='titleApply'>{t('Rules created')}</div>
                <div className='nameTypeRules'>
                    { typeModal === "display" && t('Applying in display') }
                    { typeModal === "contentDisplay" && t('Applying in spot') }
                    { typeModal === "groupDisplay" && t('Applying in group') }
                    { typeModal === "campaign" && t('Applying in campaign') }
                    <span style={{ marginLeft: "10px", color: "#12cda7" }}>{ typeName }</span>
                </div>
            </div>
            {/* <Divider className="divider"/> */}
            {libraryRulesView.length === 0 && (
                <div style={{display: "flex", justifyContent: "space-around"}}>
                    <div id="1" className='contentMessage'>
                        <p>{t("Not rules created")}</p>
                    </div>
                </div>
            )}
            {libraryRulesView.length > 0 && (
                <Collapse style={customPanelStyleContent} activeKey={activeKey} onChange={handleCollapseChange}>
                        {libraryRulesView.map((rules, index) => (
                            <Panel
                                header={
                                    <div className='contentNameRule' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>{rules.name || "rule"}</span>
                                        <div className='versionInRules'>
                                            { showVersionApplied(rules) }
                                        </div>
                                    </div>
                                }
                                key={index}
                                style={customPanelStyle}>
                                { activeKey.includes(index.toString()) &&
                                    <>
                                        {(typeModal != "contentDisplay") &&
                                            <div>
                                                <div style={{marginBottom:"8px"}}>{t('Version of the ad')}</div>
                                                <span>
                                                    { versionApplied
                                                        .map(item => (
                                                        <Checkbox
                                                            value={item}
                                                            className={!listContentDisplay.some(contentDisplay => contentDisplay.content.content_version_name === item) ? 'dayCheckedDiseabled': ''}
                                                            disabled={!listContentDisplay.some(contentDisplay => contentDisplay.content.content_version_name === item)}
                                                            checked={rules.content_version_name && rules.content_version_name.includes(item)}
                                                            onChange={(e) => loaderVersion(e, rules)}
                                                            >
                                                            {item}
                                                        </Checkbox>
                                                    ))}
                                                </span>
                                                <Divider className="divider"/>
                                            </div>
                                        }
                                        {rules.groupRules.map((rule, index) => (
                                            viewlistRules(rule, index, rules)
                                        ))}
                                        <Divider className="divider"/>
                                        <div className='containerButtonRulesCreated'>
                                            {(!hasRuleSomeDisplay(rules) ) &&
                                                <Tooltip
                                                    placement="top"
                                                    title={
                                                        <span>
                                                            {t('You are about to permanently remove this rule from the list. This action cannot be undone')}
                                                        </span>}>
                                                    <Button style={{marginRight: "10px"}} onClick={()=>removeRuleOfList(rules.id)}>
                                                        <Icon type="delete" theme="outlined"/>
                                                    </Button>
                                                </Tooltip>
                                            }
                                            {!hasRule(rules) || rules.changedValues ?
                                                <Button
                                                    type="primary"
                                                    disabled={rules.version === 0}
                                                    style={{marginRight: "10px"}}
                                                    onClick={()=>applyRuleInContentDisplayFromView(libraryRules, rules.id, rules.content_version_name)}>
                                                    <Icon type  ="plus" />{rules.changedValues ? t("Apply changes") : t("Apply rules")}
                                                </Button>
                                                :
                                                <Button type="primary" style={{marginRight: "10px"}} onClick={()=>removeRuleInContentDisplay(rules)}>
                                                    <Icon type="minus" />{t("Remove rule")}
                                                </Button>
                                            }
                                        </div>
                                    </>
                                }
                            </Panel>
                        ))}
                </Collapse>
            )}
        </RulesComponentHolder>
    );
};
export default withTranslation()(RulesComponent);