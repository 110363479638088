
import { connect } from 'react-redux'
import CardClientBrandComponent from '../components/CardClientBrandComponent'

const mapStateToProps = ({ display }) => ({
    client: display.client,
    brand: display.brand,
    programmatic: display.programmatic,
})

export default connect(mapStateToProps)(CardClientBrandComponent)