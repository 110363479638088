import styled from "styled-components";
import Colors from "../../themes/Colors";

const ModalInfoNewViewHolder =
  styled.div`
    text-align: left;
    color: white;

    .container {
      background: #1f1f1f;
      border-radius: 10px;
      padding: 20px;
    }

    .image {
      width: 100%;
      border-radius: 10px;
    }
    .gradient-title {
      font-weight: 1000;
      line-height: 1.2;
      margin-top: 15px;
      margin-bottom: 25px !important;
      text-align: center;
      background: linear-gradient(to right, #0051BF, #3FA9F5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 1000;
    }

    .ant-modal-content {
      background-color: transparent !important;
    }

    .button-container {
      text-align: center;
      margin-top: 20px;
    }

    .guide-button {
      border: 0px;
      background: linear-gradient(to right, #0051BF, #3FA9F5);
      color: white;
      font-weight: bold;
      border-radius: 5px;
      text-align: center;
      width: 133px;
      height: 40px;
    }

    .list {
      margin-top: 10px;
      margin-bottom: 25px;
      font-size: 16px;
      padding-left: 30%;
      list-style: none;
    }

    .list li {
      position: relative;
      padding-left: 30px;
      color: white;
      font-weight: bolder;
    }

    .list li::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border: 2px solid #00aaff;
      background-color: transparent;
      border-radius: 50%;
    }

    .subtitle {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      margin-top: 20px;
    }
    @media (max-width: 576px) {
      .image {
        display: none;
      }

      .gradient-title span {
        font-size: 24px;
      }

      .subtitle span {
        font-size: 24px;
      }
    }
    .video-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

  `;
export default ModalInfoNewViewHolder;