import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardClientBrandHolder = styled.div`
  .cardGeneral {
    padding-left: 10px;
    padding-bottom: 10px;
    position: fixed;
    z-index: 996;
    top: 450px;
    left: 12px;
    height: auto;
    line-height: 30px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    margin-bottom: 10px;
    width: 11%;

    @media only screen and (max-width: 1200px) {
      width: 30%;
      top: 140px;
    }
    @media only screen and (max-width: 500px) {
      width: 40%;
      top: 140px;
    }

    @media only screen and (min-width: 576px) {
      top: ${({ hasRedistribute }) =>
        hasRedistribute
          ? 'calc(150px + 320px + 180px)'
          : 'calc(150px + 330px + 180px)'};
    }

    @media only screen and (min-width: 1000px) {
      top: ${({ hasRedistribute }) =>
        hasRedistribute
          ? 'calc(60px + 220px + 250px)'
          : 'calc(60px + 190px + 250px)'};
      max-width: 11%;
    }
    @media only screen and (max-width: 1400px) {
      max-width: 11%;
      .impacts-container {
        line-height: 20px;
      }
    }
    @media only screen and (max-width: 1200px) {
      position: inherit;
      max-width: 100%;
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .infoDate {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .brandClientNameContainer {
    background-color: white;
    border-radius: 8px;
    padding: 2px;
    margin-right: 10px;
   }
   .brandClientName {
    margin: 0;
    color: ${Colors.primary};
    font-weight: 500;
   }
`;

export default CardClientBrandHolder;