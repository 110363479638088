import React, { useMemo, useState } from 'react';
import { Tooltip, Button, Icon, Modal} from 'antd';
import { useTranslation } from 'react-i18next';
import { SeparateNumberFormat } from "../services/utils";
import AudienceSummaryComponentGeneral from "./AudienceSummaryComponentGeneral";
import { getAmountAudienceDisplay, getAmountAudience } from "../services/display";
import AudienceImpactsHolder from './Styles/audienceImpactsStyle';

const AudienceImpactsComponent = ({ displays, type = 'default', audience , relationId, editCampaignActive, currentCampaign, user, audienceHoursOfDay, audienceDaysOfWeek, getAudienceHoursOfDayRequest, getAudienceDaysOfWeekRequest }) => {

  const [visibleAudienceView, setVisibleAudienceView] = useState(false);

  const [totalAudience, totalImpacts] = useMemo(() => {
    let totalAudience = 0;
    let totalImpacts = 0;
    const displayList = Array.isArray(displays) ? displays : [displays];
    if (type === 'relation') {
        totalAudience = getAmountAudience(audience, relationId) || 0;
        totalImpacts = getAmountAudience(audience, relationId, true) || 0;
      } else {
        displayList.forEach(display => {
          totalAudience += getAmountAudienceDisplay(audience, display.id) || 0;
          totalImpacts += getAmountAudienceDisplay(audience, display.id, true) || 0;
        });
    }
    return [totalAudience, totalImpacts];
  }, [audience, displays]);

  const { t } = useTranslation();
  
  if (totalAudience <= 0) {
    return null;
  }
  const getTooltipAudience = (camera) => {
    return camera
      ? t('Potential reach in real time of the screen based on the measurement data with cameras, together with the dates and rules selected. Remember to optimize your campaign based on audience data. Also remember that the greater the number of spots, the greater impression you can achieve.')
      : t('Audience data provided by the media. They are impression based on third-party measurement data. Remember to optimize your campaign based on audience data. Also remember that the more spots, the more impression you can achieve.');
  }
  const getTooltipImpacts = () => {
    return t("Real-time screen impacts, shows the number of people who will see your spot or group of spots based on camera measurement data, along with dates, selected rules, and investment made. Remember that the greater the investment, the greater the impact it will generate.");
  }
  const showAudienceModal = () => {
    setVisibleAudienceView(!visibleAudienceView);
  };
  return (
    <AudienceImpactsHolder>
      <div
        className={ type === 'relation' ? "col-displays content-audience-relation" :"col-displays content-audience" }
      >
        <div>
          <Tooltip placement="bottomLeft" title={getTooltipAudience(false)}>
            <Button
              className="screenCardAudit"
              size='small'
              style={{marginRight: "5px"}}
              onClick={() => showAudienceModal()}>
                <Icon type="usergroup-add" theme="outlined"/>
                <span>{SeparateNumberFormat(totalAudience)}</span>
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="bottomLeft" title={getTooltipImpacts()}>
            <Button className="screenCardAudit" size='small' onClick={() => showAudienceModal()}>
              <Icon type="eye" theme="outlined"/>
              <span>{SeparateNumberFormat(totalImpacts)}</span>
            </Button>
          </Tooltip>
        </div>
        {type === 'display' && (
          // Only show the modal if the type is display
          <Modal // AUDIENCE MODAL
            title={t("Audience")}
            visible={visibleAudienceView}
            onOk={() => showAudienceModal()}
            onCancel={() =>showAudienceModal()}
            width={1000}
          >
          <AudienceSummaryComponentGeneral
            t={t}
            display={displays}
            user={user}
            campaign={editCampaignActive ? currentCampaign : null}
            audienceHoursOfDay = {audienceHoursOfDay}
            audienceDaysOfWeek = {audienceDaysOfWeek}
            getAudienceHoursOfDayRequest={getAudienceHoursOfDayRequest}
            getAudienceDaysOfWeekRequest={getAudienceDaysOfWeekRequest}
            />
          </Modal>
        )}
      </div>
    </AudienceImpactsHolder>
  );
};
export default AudienceImpactsComponent;