/* global google */
import React, { Component } from "react";
import moment from "moment";
import {
  Menu,
  Dropdown,
  Row,
  Col,
  Pagination,
  Icon,
  Input,
  Skeleton,
  Typography,
  Divider,
  Switch,
  Alert,
  Card,
  Checkbox,
  Spin,
} from "antd";
import { withRouter } from "react-router";
import queryString from "query-string";
import { isEqual } from "lodash";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import MapFiltersComponent from "./MapFiltersComponent";

import MapHolder from "./Styles/mapStyle";
import SelectionFiltersContainer from "../containers/SelectionFiltersContainer";
import CartContainer from "../containers/CartContainer";
import CartAudienceContainer from "../containers/CartAudienceContainer";

import ScreenCardContainer from "../containers/ScreenCardContainer";
import { ResultsMap } from "./ResultsMap";

import { convertBounds, adaptParams, updateParams, getFilterDisplaysForContains} from "../services/display";

import { withTranslation } from "react-i18next";
import { hasAccessRole } from "../services/utils";
import UserRoleEnum from "../constants/UserRoleEnum";
import { getMinimunByCurrency } from "../services/utils";
import Colors from "../themes/Colors";
import { saveAs } from 'file-saver';
import UrlEnum from "../constants/UrlEnum";
import { getDomain } from "../services/utils";

const CheckSearch = Card;
class MapComponent extends Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(props.history.location.search, {
      arrayFormat: "bracket",
    });
    this.searchDisplays = this.searchDisplays.bind(this);
    props.setParamsFromUrl(adaptParams(params));
    this.state = {
      hoverDisplay: null,
      currentDisplay: null,
      address: "",
      searchPlace: "",
      redirect: false,
      relocateMap: false,
      searchLocation: false,
      isProgrammaticFilterHidden: true,
      latitude: (this.props.lat_sw + this.props.lat_ne) / 2,
      longitude: (this.props.lng_sw + this.props.lng_ne) / 2,
      isMobile: window.innerWidth < 576,
      allDisplays: false,
      errors: {},
      search: null,
      setProgrammatic: false,
      arrayPolygon: [],
      checkSearchMap: true,
      companiesOfWhiteBrand : null
    };
  }


  handleChangeAddress = (address) => {
    this.setState({ address });
  };

  setCheckSearchMap = (e) => {
    let value = ! e.target.value;
    this.setState({ checkSearchMap: value });
  };

  handleSelectAddress = (address) => {
    this.setState({ address });
    return geocodeByAddress(address)
      .then((results) => {
        if (results) {
          const coords = convertBounds(results[0].geometry.viewport);
          this.props.setCoords(coords);
          if (results[0].types[0] === "continent") {
            this.props.setMapZoom(2);
          } else if (results[0].types[0] === "country") {
            this.props.setMapZoom(5);
          } else if (results[0].types[0] === "administrative_area_level_1") {
            this.props.setMapZoom(7);
          } else if (results[0].types[0] === "administrative_area_level_2") {
            this.props.setMapZoom(9);
          } else if (results[0].types[0] === "colloquial_area") {
            this.props.setMapZoom(11);
          } else if (results[0].types[0] === "locality") {
            this.props.setMapZoom(12);
          } else if (
            results[0].types[0] === "political" ||
            (results[0].lenght > 1 && results[0].types[1] === "sublocality")
          ) {
            this.props.setMapZoom(14);
          } else if (
            results[0].types[0] === "intersection" ||
            results[0].types[0] === "street_address" ||
            results[0].types[0] === "street_number" ||
            results[0].types[0] === "route"
          ) {
            this.props.setMapZoom(18);
          } else if (
            results[0].types[0] === "establishment" ||
            results[0].types[0] === "point_of_interest" ||
            results[0].types[0] === "shopping_mall"
          ) {
            this.props.setMapZoom(16);
          }
        }
        this.setState({ relocateMap: true });
        setTimeout(() => {
          this.setState({
            searchLocation: true,
          });
          this.refreshDisplays();
        }, 300);
      })
      .catch((error) => console.error("Error", error));
  };

  handleSelectPlace = (typedPlace) => {
    this.setState({ searchPlace: typedPlace });
  };

  // updateCenter(lat, lng) {
  //   this.setState({
  //     latitude: lat,
  //     longitude: lng,
  //   });
  // }

  hoverDisplay(display) {
    this.setState({ hoverDisplay: display });
  }

  searchDisplays(e) {
    this.setState({ search: e.target.value });
    setTimeout(() => {
      this.refreshDisplays();
    }, 1000);
  }

  openDisplay(display) {
    this.setState({ currentDisplay: display } /*, this.refreshDisplays*/);
  }

  closeDisplay(display) {
    this.setState({ currentDisplay: null });
  }

  componentDidMount() {

    this.props.resetPoiFilters();

    if (
      this.props.cart.length > 0 &&
      this.props.cart[0].currency != this.props.currency
    ) {
      this.updateCartByCurrency();
    }
    if (!this.props.user) {
      this.props.setShouldPromptLogin(true, null);
    }
    if (queryString.parse(this.props.location.search, {arrayFormat: "bracket"}).displays_list) {
      this.updateCartByDisplaysList(queryString.parse(this.props.location.search, {arrayFormat: "bracket"}).displays_list);
    }
    if (queryString.parse(this.props.location.search, {arrayFormat: "bracket"}).programmatic === 'true') {
      this.props.setProgrammatic('programmatic')
    }
    if (queryString.parse(this.props.location.search, {arrayFormat: "bracket"}).budget) {
      this.props.setBudget(queryString.parse(this.props.location.search, {arrayFormat: "bracket"}).budget)
    }

    // When the domain is smartfit or duoprint, it only needs to fetch the screens for that company.
    const domainMappings = {
      smartFit: UrlEnum.companiesSmartfit,
      duoPrint: UrlEnum.companiesDuoPrint
    };

    let listDomian = ["smartFit", "duoPrint"];
    let domain = getDomain(window.location.hostname);


    if (listDomian.includes(domain)) {
      const listCompanies = domainMappings[domain];
      this.setState({ companiesOfWhiteBrand: listCompanies });
    }

  }

  updateCartByDisplaysList(displays_list) {
    const arrayDisplays = displays_list.split(',');
    this.updateCart (arrayDisplays);
  }

  updateCartByCurrency() {
    const { cart} = this.props;
    const list_id = cart.map((item) => item.id);
    this.updateCart(list_id);
  }

  updateCart (list_display) {
    const { date_from, date_to, currency, invoiceIssuingCountry, client, programmatic , dsp, editCampaignActive} = this.props;

    this.props.getDisplaysCart({
      date_from: moment(date_from || new Date()).format(
        "YYYY-MM-DD"
      ),
      date_to: moment(date_to || new Date()).format("YYYY-MM-DD"),
      currency: currency,
      invoice_issuing_country: invoiceIssuingCountry,
      client_id: client ? client.id : null,
      list_id: list_display,
      external_dsp_id: dsp ? dsp.id : null,
      smart_campaign_enabled: programmatic === "programmatic" ? 1 : null,
      is_campaign: editCampaignActive ? 1 : 0
  })}

  componentDidUpdate(prevProps, prevState) {
    //download excel
    if (prevProps.loadingExcel && this.props.excelDisplay) this.downloadExcel()

    if (this.props.redirectTarget) {
      return;
    }
    // Get parameters from the URL when creating a proposal
    const oldParams = adaptParams(
      queryString.parse(this.props.history.location.search, {
        arrayFormat: "bracket",
      })
    );

    const changedTheBrand = prevProps.brand && this.props.brand.id && (prevProps.brand.id != this.props.brand.id);

    if ( prevState.allDisplays != this.state.allDisplays || changedTheBrand ) this.refreshDisplays();
    
    let dspId = this.props.dsp ? this.props.dsp.id : null;
    if ( oldParams.external_dsp_id && (oldParams.external_dsp_id != dspId)){
      let paramsDsp = parseInt(oldParams.external_dsp_id)
      this.props.setDsp(this.props.dsps.find(dsp => dsp.id === paramsDsp))
    }

    if (
      (( prevProps.currency != this.props.currency || prevProps.dsp != this.props.dsp ) &&
       this.props.cart.length > 0) || changedTheBrand
     ) {
       this.updateCartByCurrency();
     }

    const newParams = updateParams(oldParams, {
      date_from: moment(this.props.date_from || new Date()).format(
        "YYYY-MM-DD"
      ),
      date_to: moment(this.props.date_to || new Date()).format("YYYY-MM-DD"),
      lat_sw: this.props.lat_sw,
      lng_sw: this.props.lng_sw,
      lat_ne: this.props.lat_ne,
      lng_ne: this.props.lng_ne,
      price_min: this.props.price_min,
      price_max: this.props.price_max,
      shows_per_day_min: this.props.shows_per_day_min,
      location_type: this.props.location_type,
      location_sub_type: this.props.location_sub_type,
      size_type: this.props.size_type,
      is_circuit: this.props.isCircuit,
      page: this.props.page,
      currency: this.props.currency,
    });
    oldParams.currency = prevProps.currency;
    oldParams.location_type = oldParams.location_type || [];
    oldParams.location_sub_type = oldParams.location_sub_type || [];
    oldParams.size_type = oldParams.size_type || [];
    oldParams.is_circuit = prevProps.isCircuit;
    if (isEqual(oldParams, newParams)) {
      return;
    } else {
      setTimeout(() => {
        this.refreshDisplays();
      }, 1000)
    }
    this.props.history.replace(
      "/map?" + queryString.stringify(newParams, { arrayFormat: "bracket" })
    );
  }

  handleAmountChange(e) {
    this.props.setBudget(e.target.value);
    this.checkMinimumBudget(e);
  }

  checkMinimumBudget(e) {
    const errors = {};
    const minimun = getMinimunByCurrency(this.props.currency);
    if (parseInt(e.target.value) < minimun) {
      errors["budget"] = this.props.t("Minumum budget") + minimun;
    }
    this.setState({ errors });
  }

  getCompanies() {
    const { user } = this.props;
    const { allDisplays, companiesOfWhiteBrand } = this.state;

    if (companiesOfWhiteBrand) {
      return companiesOfWhiteBrand;
    }

    if (user && user.roles) {
      const hasCompanyAccess = hasAccessRole(user.roles, [UserRoleEnum.companyMember]) && !allDisplays;

      // The agency is in the media holding with other companies of type media company
      const hasAgencyAccess = hasAccessRole(user.roles, [UserRoleEnum.agencyMember]) && user.is_in_media_holding && !allDisplays;

        if (hasCompanyAccess || hasAgencyAccess) {
          return [user.company.id];
        }
    }
    return null;
  }

  refreshDisplays(poiFilters = null) {
    const { poi_filters, editCampaignActive } = this.props;
    const {companiesOfWhiteBrand} = this.state;
    if(!this.state.checkSearchMap){
      return;
    }
    // Check if poi_filters is null, if it is, use the one in the state
    poiFilters = poiFilters || poi_filters;

    if (!this.props.user) {
      this.props.setShouldPromptLogin(true, null);
    } else {
      this.props.getDisplays({
        date_from: moment(this.props.date_from || new Date()).format(
          "YYYY-MM-DD"
        ),
        date_to: moment(this.props.date_to || new Date()).format("YYYY-MM-DD"),
        lat_sw: this.props.lat_sw,
        lng_sw: this.props.lng_sw,
        lat_ne: this.props.lat_ne,
        lng_ne: this.props.lng_ne,
        search: this.state.search,
        price_min: this.props.price_min,
        price_max: this.props.price_max,
        shows_per_day_min: this.props.shows_per_day_min,
        location_type: this.props.location_type,
        location_sub_type: this.props.location_sub_type,
        size_type: this.props.size_type,
        is_circuit: this.props.isCircuit,
        custom_poi_type: poiFilters,
        page: this.props.page,
        client_id: this.props.client ? this.props.client.id : null,
        countries: this.props.countries,
        currency: this.props.currency,
        external_dsp_id: this.props.dsp ? this.props.dsp.id : null,
        invoice_issuing_country: this.props.invoiceIssuingCountry,
        smart_campaign_enabled:
          this.props.programmatic && this.props.programmatic === "programmatic"
            ? 1
            : null,
        company_id: this.getCompanies(),
        is_campaign: editCampaignActive ? 1 : 0,
      });
    }
  }

  handleBoostBanner = () => {
    this.setState((prevState) => ({
      showBoost: !prevState.showBoost,
    }));
  };

  mapRelocated() {
    this.setState({ relocateMap: false });
  }

  searchLocationOff() {
    this.setState({ searchLocation: false });
  }

  viewAllDisplays(allDisplayChecked) {
    this.setState({ allDisplays: !allDisplayChecked });
  }

  updateArrayPolygon = (array) => {

    this.setState({
      arrayPolygon: [...this.state.arrayPolygon, ...array],
    });
  };

  cleanArrayPolygon = () => {
    this.setState({ arrayPolygon: [] });
  };

  cleanFilters = () => {
    this.props.setSizeType([]);
    this.props.setLocationType([]);
    this.props.setLocationSubType([]);
  };

  downloadExcel = () => {
    const { excelDisplay } = this.props;
    saveAs(excelDisplay, 'DisplaysLatinAd');
  }

  render() {
    const { t, i18n, user, fetching} = this.props;
    const { address, searchPlace, arrayPolygon , checkSearchMap, companiesOfWhiteBrand} = this.state;

    moment.locale(i18n.language);

    return (
      <MapHolder>
        <CartContainer
          displays={this.state.displaysAudience}
          action={this.state.displaysAction}
          cleanArrayPolygon={this.cleanArrayPolygon}
          cleanFilters={this.cleanFilters}
        />
        <Row className="searchResultsContainer" type="flex" justify="center">
          {this.state.isMobile ? (
            <Col
              className="resultMapContainerMobile"
              xs={24}
              sm={0}
              md={0}
              lg={0}
              xl={0}
            >
              <Row style={{ widht: "100%" }} type="flex" justify="end">
                <div className="zonePickerContainer">
                  <div className="zonePicker">
                    <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChangeAddress}
                      onSelect={this.handleSelectAddress}
                      searchOptions={{
                        componentRestrictions: {
                          // Google allows to put only 5 countries in this array
                          // country: ['ar', 'mx', 'es', 'gt', 'hn', 'ni', 'sv', 'bz', 'pa', 'cr', 'do']
                        },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Dropdown
                            visible={this.state.address !== ""}
                            trigger={["click"]}
                            overlay={
                              <Menu>
                                {loading && (
                                  <Menu.Item>{t("Loading")}...</Menu.Item>
                                )}
                                {suggestions.map((suggestion) => {
                                  return (
                                    <Menu.Item
                                      key={suggestion.id}
                                      {...getSuggestionItemProps(suggestion, {
                                        className: suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item",
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </Menu.Item>
                                  );
                                })}
                              </Menu>
                            }
                          >
                            <Input
                              style={{ borderColor: Colors.primary }}
                              addonBefore={
                                <div>
                                  <Icon type="search" />
                                  {t("Campaign location")}
                                </div>
                              }
                              size="large"
                              placeholder="large size"
                              {...getInputProps({
                                placeholder: t("Location, street or city"),
                                className: "location-search-input",
                              })}
                            />
                          </Dropdown>
                          <CheckSearch style={{ pointerEvents: "all", width: "40%" }} className="customCheckStyle">
                            <span className="ant-checkbox-wrapper">
                              <Spin spinning={fetching}>
                                <Checkbox
                                  onChange={this.setCheckSearchMap}
                                  defaultChecked={checkSearchMap}
                                  value={checkSearchMap}>
                                  {t("Search on move")}
                                </Checkbox>
                              </Spin>
                            </span>
                          </CheckSearch>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </Row>
              <ResultsMap
                bounds={{
                  south: this.props.lat_sw,
                  west: this.props.lng_sw,
                  north: this.props.lat_ne,
                  east: this.props.lng_ne,
                }}
                center={{
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                }}
                // updateCenter={this.updateCenter.bind(this)}
                defaultCenter={{
                  lat: (this.props.lat_sw + this.props.lat_ne) / 2,
                  lng: (this.props.lng_sw + this.props.lng_ne) / 2,
                }}
                user={this.props.user}
                mapZoom={this.props.mapZoom}
                setMapZoom={this.props.setMapZoom}
                addDisplayToCart={this.props.addDisplayToCart}
                addListDisplayToCart={this.props.addListDisplayToCart}
                date_from={this.props.date_from}
                date_to={this.props.date_to}
                getAudience={this.props.getAudience}
                cleanAudience={this.props.cleanAudience}
                getDisplayInfoRequest={this.props.getDisplayInfoRequest}
                removeDisplayFromCart={this.props.removeDisplayFromCart}
                cart={this.props.cart}
                setBounds={this.props.setBounds}
                refreshDisplays={() => this.refreshDisplays()}
                displays={this.props.displays}
                relocateMap={this.state.relocateMap}
                mapRelocated={() => this.mapRelocated()}
                searchLocationOff={() => this.searchLocationOff()}
                searchLocation={this.state.searchLocation}
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={
                  <div style={{ height: `100%`, borderRadius: "16px" }} />
                }
                hoverDisplay={this.state.hoverDisplay}
                currentDisplay={this.state.currentDisplay}
                currency={this.props.currency}
                openDisplay={this.openDisplay.bind(this)}
                closeDisplay={this.closeDisplay.bind(this)}
                programmatic={this.props.programmatic}
                editCampaignActive={this.props.editCampaignActive}
                editCampaign={this.props.editCampaign}
                searchPlace={searchPlace}
                listBundle={this.props.listBundle}
                containerElement={<div className="mapHeightMobile" />}
              />
            </Col>
          ) : null}
            <div className="programmaticSection">
              <CartAudienceContainer />
            </div>
          <Col className="listContainer" xs={24} sm={8} md={8} lg={8} xl={8}>
            <div className="positionAbs">
              <SelectionFiltersContainer
                refreshDisplays={() => this.refreshDisplays()}
                openDisplay={this.openDisplay.bind(this)}
              />
              <Divider style={{ margin: "10px 0px" }}>
                {this.props.fetching ? (
                  <span className="screensQuantity">
                    {t("Getting results")}
                  </span>
                ) : (
                  <span className="screensQuantity">
                    {this.props.total} {t("screen")}
                    {this.props.total === 1 ? "" : "s"}
                  </span>
                )}
              </Divider>
              <Row display="flex" className="searchSection">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Input
                    prefix={
                      <Icon
                        type="search"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    placeholder={t("Search")}
                    className="searchInput"
                    onChange={this.searchDisplays}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="rowSearch">
                    { user &&
                      user.roles &&
                      !companiesOfWhiteBrand &&
                      (hasAccessRole(user.roles, [UserRoleEnum.companyMember]) || user.is_in_media_holding) ? (
                        <span className="allDisplaysSwitch">
                          <span style={{ paddingRight: "5%" }}>
                            {t("Show all")}
                          </span>
                          <Switch
                            defaultChecked={this.state.allDisplays}
                            checked={this.state.allDisplays}
                            onChange={() =>
                              this.viewAllDisplays(this.state.allDisplays)
                            }
                          />
                        </span>
                      ) : null}
                  </div>
                </Col>
              </Row>
            </div>
            {this.state.isMobile ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  position: "absolute",
                }}
              >
                <Icon className="lineSepareteIcon" type="line" />
              </div>
            ) : null}
            <div className="listContent">
              {this.props.fetching ? (
                <div>
                  <Row gutter={16}>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                      <Col key={i} xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Skeleton active avatar paragraph={{ rows: 4 }} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <>
                  {this.props.total === 0 &&
                    this.props.countries.length > 0 &&
                      <Alert
                      style={{width: "96%", position: "absolute", zIndex: 1 }}
                        message={<div>{t("Results are filtered by country. You can")} <a onClick={() => this.props.setCountries([])}>{t("remove that filter and try again.")}</a></div>}
                        type="warning"
                        showIcon
                      />
                  }
                  <Row
                    className="cardList"
                    // HERE BEGINS CARDS .map
                  >
                    {this.props.displays &&
                      this.props.displays.map((display) => (
                        <span>
                          {getFilterDisplaysForContains(display, this.props.user)? (
                            <Col
                              key={display.id}
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <div
                                onClick={() => this.openDisplay(display)}
                                onMouseEnter={() => this.hoverDisplay(display)}
                                onMouseLeave={() => this.hoverDisplay()}
                              >
                                <ScreenCardContainer
                                  user={this.props.user}
                                  display={display}
                                  isCurrentDisplay={
                                    this.state.currentDisplay &&
                                    this.state.currentDisplay.id === display.id
                                  }
                                />
                              </div>
                            </Col>
                          ) : null}
                        </span>
                      ))}
                    {this.props.total > 0 &&
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="paginationContainer">
                        <Pagination
                          current={this.props.page}
                          pageSize={this.props.perPage}
                          total={this.props.total}
                          onChange={(page) => {
                            this.props.setPage(page);
                          }}
                        />
                      </div>
                    </Col>}
                  </Row>
                </>

              )}
            </div>
          </Col>
          {!this.state.isMobile ? (
            <Col
              className="resultMapContainer"
              xs={0}
              sm={16}
              md={16}
              lg={16}
              xl={16}
            >
              <MapFiltersComponent
                t={t}
                setPoiFilters={this.props.setPoiFilters}
                refreshDisplays={(arr) => this.refreshDisplays(arr)}
                resetPoiFilters={this.props.resetPoiFilters}
                poiFilters={this.props.poi_filters}
                address={address}
                searchPlace={searchPlace}
                handleChangeAddress={this.handleChangeAddress}
                handleSelectAddress={this.handleSelectAddress}
                handleSelectPlace={this.handleSelectPlace}
                setCheckSearchMap={this.setCheckSearchMap}
                checkSearchMap={this.state.checkSearchMap}
                fetching={this.props.fetching}
              />

              <ResultsMap
                bounds={{
                  south: this.props.lat_sw,
                  west: this.props.lng_sw,
                  north: this.props.lat_ne,
                  east: this.props.lng_ne,
                }}
                center={{
                  lat: this.state.latitude,
                  lng: this.state.longitude,
                }}
                // updateCenter={this.updateCenter.bind(this)}
                defaultCenter={{
                  lat: (this.props.lat_sw + this.props.lat_ne) / 2,
                  lng: (this.props.lng_sw + this.props.lng_ne) / 2,
                }}
                user={this.props.user}
                mapZoom={this.props.mapZoom}
                setMapZoom={this.props.setMapZoom}
                addDisplayToCart={this.props.addDisplayToCart}
                addListDisplayToCart={this.props.addListDisplayToCart}
                date_from={this.props.date_from}
                date_to={this.props.date_to}
                getAudience={this.props.getAudience}
                cleanAudience={this.props.cleanAudience}
                removeDisplayFromCart={this.props.removeDisplayFromCart}
                cart={this.props.cart}
                setBounds={this.props.setBounds}
                refreshDisplays={() => this.refreshDisplays()}
                displays={this.props.displays}
                relocateMap={this.state.relocateMap}
                mapRelocated={() => this.mapRelocated()}
                searchLocationOff={() => this.searchLocationOff()}
                searchLocation={this.state.searchLocation}
                loadingElement={<div style={{ height: `100%` }} />}
                mapElement={
                  <div style={{ height: "100%", borderRadius: "16px" }} />
                }
                hoverDisplay={this.state.hoverDisplay}
                currentDisplay={this.state.currentDisplay}
                currency={this.props.currency}
                openDisplay={this.openDisplay.bind(this)}
                closeDisplay={this.closeDisplay.bind(this)}
                programmatic={this.props.programmatic}
                editCampaignActive={this.props.editCampaignActive}
                editCampaign={this.props.editCampaign}
                containerElement={<div className="mapHeight" />}
                searchPlace={searchPlace}
                arrayPolygon={arrayPolygon || []}
                updateArrayPolygon={this.updateArrayPolygon}
                listBundle={this.props.listBundle}
              />
            </Col>
          ) : null}
        </Row>
      </MapHolder>
    );
  }
}

const Extended = withTranslation()(MapComponent);
Extended.static = MapComponent.static;

export default withRouter(Extended);
