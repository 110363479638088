
import React from 'react';
import { Row, Col } from "antd";
import { useTranslation } from 'react-i18next';
import CardDSPScreensHolder from './Styles/cardDSPScreensStyle';


const CardDSPScreensComponent = (props) => {
  const { t } = useTranslation();
  const { stepsCurrent,  cart, programmatic, dsp } = props;

  return (
    <CardDSPScreensHolder hasRedistribute={programmatic === "programmatic" && stepsCurrent !== 0}>
      <div className="cardGeneral">
        <Row justify="center">
          <Col>
            <div>
              <div className='infoDate'>{t("DSP")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{ (dsp && dsp.name) || "Latinad" }</p>
              </div>
              <div className='infoDate'>{t("Number of screens")}</div>
              <div className='brandClientNameContainer'>
                <p className='brandClientName'>{cart.length}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CardDSPScreensHolder>
  );
};

export default CardDSPScreensComponent;