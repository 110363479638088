
import { connect } from 'react-redux'
import CardAdsInfoComponent from '../components/CardAdsInfoComponent'

const mapStateToProps = ({ display }) => ({
    dsp: display.dsp,
    programmatic: display.programmatic,
    campaign: display.campaign,
    listOfContentsInCampaign: display.listOfContentsInCampaign,
    listResolutionWithContent: display.listResolutionWithContent,
})

export default connect(mapStateToProps)(CardAdsInfoComponent)