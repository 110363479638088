import React, { Component } from "react";
import CartHolder from "./Styles/cartStyle";
import ScreenCardContainer from "../containers/ScreenCardCheckoutContainer";
import { Drawer, Button, Badge, Icon, Row, Col, Modal, Input, Form, message, Tooltip, Switch} from "antd";
import { withRouter } from "react-router";
import queryString from "query-string";
import { companyNewView, userNewView }  from "../constants/CompanyNewVersionEnum";
import { withTranslation } from 'react-i18next';
import NumberFormat from "./NumberFormat";
import UserRoleEnum from "../constants/UserRoleEnum";
import { hasAccessRole, getMinimunByCurrency, getUrl, SeparateNumberFormat, getDomain } from "../services/utils";
import { getTotalPice} from "../services/display";
import Colors from "../themes/Colors";
import { AimSvg } from "../images/customIcons/AimSvg";
import ModalInfoNewViewComponent from "./ModalInfoNewViewComponent";

const AimIcon = (props) => <Icon component={AimSvg} {...props} />;
const urlMap = process.env.REACT_APP_MAP;

const DOMAIN_TO_BRAND = {
  grupoVia: 'grupovia',
  fenix: 'fenix',
  mediaShake: 'media_shake',
  smartFit: 'smart_fit',
  duoPrint: 'duoprint',
  territorioDigital: 'brick_thunder',
  movImagen: 'movimagen',
  yoda: 'yoda',
  hakMedia: 'hak_media'
};

class CartComponent extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  state = {
    visible: false,
    quote: "",
    companyData: "",
    profitQuote: "",
    nameQuote: "",
    displays: "",
    quoteModalVisible: false,
    errors: {},
    creatingQuote: false,
    currenciesModalVisible: false,
    isDevEnvironment: process.env.REACT_APP_API_ENDPOINT === "https://api.dev.publinet.io",
    viewSwitchNewVersion: false,
    handlInfoNewViewOk: false,
    usersCanAccessNewView: false,
    companyCanAccessNewView: false
  };

  componentDidMount = () => {
    const { cleanFilters, getCurrencyExchangeRate, dsp , user, editCampaignActive, currentCampaign } = this.props;
    cleanFilters();
    if (!!dsp && dsp.id) {
      getCurrencyExchangeRate();
    }

    const usersCanAccessNewView = ( this.props.isSuperUser || (this.props.user && userNewView.includes(this.props.user.id)));
    this.setState({ usersCanAccessNewView })

    const companyCanAccessNewView = user && user.company && companyNewView.includes(user.company.id);
    this.setState({ companyCanAccessNewView })

    if( !editCampaignActive && (usersCanAccessNewView || companyCanAccessNewView )) {
      this.setState({
        viewSwitchNewVersion: true
      })
    }

    // Default value is true if not have switch is necesaru setting false
    if(!editCampaignActive){
      if((!usersCanAccessNewView && !companyCanAccessNewView)){
        this.props.setNewVersionCampaign(false);
      } else {
        this.props.setNewVersionCampaign(true);
      }
    }

    if(editCampaignActive && currentCampaign.new_ssp) {
      this.props.setNewVersionCampaign(true);
    }
  }

  UNSAFE_componentWillMount() {
    const params = queryString.parse(this.props.history.location.search, {
      arrayFormat: "bracket",
    });
    if (params.is_quote) {
      this.setState({
        visible: true,
      });
    }
    if (params.quote_profit) {
      this.setState({
        quote: params.quote_profit,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isSuperUser, editCampaignActive } = this.props;
    const { usersCanAccessNewView, companyCanAccessNewView } = this.state;

    if (this.props.creatingQuote && !nextProps.creatingQuote) {
      if (nextProps.errors.length === 0) {
        let lenguageParse
        switch (nextProps.i18n.language) {
          case "es":
            lenguageParse = "esLA"
            break;
          case "es_ar":
            lenguageParse = "es"
            break;
          default:
            break;
        }
        const parameter = `lenguage=${lenguageParse || nextProps.i18n.language}&token=${nextProps.quote.token}&company=${this.state.companyData}`
        window.open(getUrl(window.location, "quote.html", parameter));
        this.handleQuoteCancel();
        message.success(this.props.t("Quote created successfully"));
        return;
      }
      message.error(
        this.props.t("An error occurred while trying to create a quote")
      );
    }

    if (
      !editCampaignActive && this.props.cart.length === 0 &&
      nextProps.cart.length > 0 &&
      !isSuperUser &&
      (usersCanAccessNewView || companyCanAccessNewView) ){
      this.setState({
        handlInfoNewViewOk: true
      })
    }
  }

  handleInputChange(event) {
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleQuoteCancel = (e) => {
    this.setState({
      quoteModalVisible: false,
    });
    Modal.destroyAll();
  };

  handleInfoNewViewCancel = (e) => {
    this.setState({
      handlInfoNewViewOk: false,
    });
  };

  showCurrenciesModal = (e) => {
    e.stopPropagation();
    this.setState({
      currenciesModalVisible: true,
    });
  };

  hideCurrenciesModal = () => {
    this.setState({
      currenciesModalVisible: false,
    });
  };

  showQuoteModal = () => {
    this.setState({
      quoteModalVisible: true,
    });
  };

  handleCreateQuote(companyData) {
    const errors = {};

    if (!this.state.nameQuote) {
      errors["nameQuote"] = this.props.t("Can not be blank");
    }

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });

      return;
    }

    const quoteData = {
      name: this.state.nameQuote,
      brand: this.props.brand.id,
      client: this.props.client.id,
      properties: {
        budget:
          this.props.programmatic === "programmatic"
            ? parseFloat(this.props.amountBudget)
            : null,
        displays: this.state.displays,
        from_date: this.props.date_from,
        to_date: this.props.date_to,
        currency: this.props.currency,
        external_dsp_id: this.props.dsp ? this.props.dsp.id: null,
        invoice_issuing_country: this.props.invoiceIssuingCountry
      }
    }
    this.props.createQuote(quoteData)
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  setNewVersionCampaign = (newVersionCampaign) => {
    this.props.setNewVersionCampaign(newVersionCampaign);
    this.props.clearCustomContents();
  }

  checkMinimumBudget(){
    const minimun = getMinimunByCurrency(this.props.currency)
    if (this.props.amountBudget == "" || parseInt(this.props.amountBudget) < minimun) {
      return true
    } else {
      return false
    }
  }

  exportXLS = () => {
    const displays = this.props.cart.map(display => ({
      id: display.id,
      price: this.props.programmatic !== 'traditional' ? display.smart_campaign_cpm / 1000 : display.price_per_day,
      currency: display.price_currency
    }));
    const params = {
      displays: displays,
      date_from: this.props.date_from,
      date_to: this.props.date_to,
      format: 'xls',
      _method: 'GET',
      smart_campaign: this.props.programmatic !== 'traditional' ? 1 : 0
    }
    this.props.getExcelDisplays(params);
  }

  setURLExportPNG = (e) => {
    let displays = this.props.cart.map(function (display) {
      if(!display.count_secondary_displays) return display.id
      if(display.count_secondary_displays) return display.secondary_displays_ids
    });
    displays = displays.toString();

    const domain = getDomain(window.location.hostname)
    let brand = DOMAIN_TO_BRAND[domain] || null;

    window.open(`${urlMap}/?id=${displays}${brand ? `&brand=${brand}` : ''}`,"_blank");
  }

  render() {
    const { t, i18n, user, cleanFilters, cleanArrayPolygon , date_from, date_to, loadingExcel, invoiceIssuingCountry, currencyExchangeRate, dsp, newVersionCampaign} = this.props;
    const { errors, viewSwitchNewVersion } = this.state;
    let priceTraditional = 0
    if(this.props.programmatic != "programmatic"){
      priceTraditional = getTotalPice(this.props.cart, date_from, date_to)
    }
    const isProgrammaticProviders = this.props.user && this.props.user.holding && this.props.user.holding && this.props.user.holding.id === 50;

    return (
      <CartHolder>
        <Button
          className={
            this.props.cart.length > 0 ? "cartButton" : "cartButtonHidden"
          }
          type="primary"
          onClick={this.showDrawer}
        >
          {t("See cart")}
          <br />
          {this.props.cart.length > 0 ? (
            <NumberFormat
              value={
                this.props.programmatic === "programmatic"
                  ? this.props.amountBudget
                  : priceTraditional
              }
              currency={this.props.cart[0].price_currency}
            />
          ) : null}
          <Badge
            count={this.props.cart.length}
            style={{
              backgroundColor: Colors.atention,
              left: "22px",
              bottom: "2px",
            }}
          >
            <Icon
              style={{ fontSize: "25px", marginLeft: "15px" }}
              type="shopping-cart"
              theme="outlined"
            />
          </Badge>
          {currencyExchangeRate && currencyExchangeRate.currency != "USD" && !!dsp && dsp.id && <>
            <br />
            <hr style={{ borderTop: "1p solid #FFFFFF", width: "100%", margin: "4px" }} />
            <Button onClick={(e) => { this.showCurrenciesModal(e); }} block size="small" className="currencyExchangeButton">
              {t("See currency exchange rate")}
            </Button>
          </>}
        </Button>

        <Drawer
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.props.cart.length > 0 ? this.state.visible : false}
          width="300"
        >
          <div
            className={
              this.props.editCampaign.payment_status === "approved" &&
              this.props.editCampaignActive
                ? "disabledCart drawerContainer"
                : "drawerContainer"
            }
          >
            <h5>
              {this.props.cart.length} {t("screen")}
              {this.props.cart.length === 1 ? "" : "s"} {t("added")}
              {(i18n.language == "es" || i18n.language == "es_ar" ) && this.props.cart.length > 1
                ? "s"
                : ""}{" "}
              {t("to cart")}
            </h5>
            {this.props.programmatic === "programmatic" ? (
              <p>
                {t("Estimated campaign budget")}{" "}
                <Tooltip
                  placement="left"
                  title={t(
                    "In the next step you will be able to configure the impressions per screen and you will see how the budget varies according to the impact you want."
                  )}
                >
                  <Icon type="question-circle" theme="outlined" />
                </Tooltip>
                {this.checkMinimumBudget() ? (
                  <p style={{ fontSize: "10px", color: "#f44336" }}>
                    {t("The minimum purchase price is not reached")}
                    <p style={{ fontSize: "12px", color: "#f44336" }}>
                        <NumberFormat
                          tax={false}
                          value={getMinimunByCurrency(this.props.currency)}
                          currency={this.props.currency}
                        />
                    </p>
                    </p>
                ) : null}
              </p>
            ) : (
              <p>{t("Total campaign price")}</p>
            )}
            <h3>
              {this.props.cart[0] ? (
                <NumberFormat
                  tax={invoiceIssuingCountry != 'F'}
                  value={
                    this.props.programmatic === "programmatic"
                      ? this.props.amountBudget
                      : priceTraditional
                  }
                  currency={this.props.cart[0].price_currency}
                />
              ) : null}
            </h3>
            {this.props.audience.total_audience > 0 ? (
              <span>
                <p>{t("Estimated audience")} </p>
                <h3 style={{ marginBottom: "5px", fontSize: "15px"}}>
                  <Tooltip
                    placement="left"
                    title={`${
                      this.props.audience &&
                      this.props.audience.displays_with_audience
                        ? this.props.audience.displays_with_audience.length
                        : t("Some")
                    } ${t(
                      "screens that you added to the cart have cameras measuring audience. You can go to the detail of each one to analyze the data and optimize your campaign"
                    )}.`}
                  >
                    <Icon type="usergroup-add" theme="outlined" style={{marginRight: "7px"}}/>
                    {SeparateNumberFormat(this.props.audience.total_audience)}{" "}
                    {this.props.loadingAudience ? <Icon type="loading" /> : null}{" "}
                    <span style={{ fontSize: "10px" }}>{t('Audience impacts')}</span>
                  </Tooltip>{" "}
                </h3>
                <h3 style={{ fontSize: "15px"}}>
                  <Tooltip
                    placement="left"
                    title={`${t(
                      "Estimated impressions based on campaign settings. You can add campaign days (in traditional) or increase the budget (in programmatic)."
                    )}`}
                  >
                    <AimIcon className="aimIcon" style={{ marginRight: "7px" }}/>
                    {SeparateNumberFormat(this.props.audience.total_impacts)}
                    <span style={{ fontSize: "11px" }}> {t('impacts')}</span>
                  </Tooltip>{" "}
                </h3>
              </span>
            ) : null}
            { viewSwitchNewVersion &&
              <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "10px", fontSize: "smaller" }}>
                <p> {t('Try our new version')}</p>
                <Switch size="small"
                  onChange={(newVersionCampaign) => this.setNewVersionCampaign(newVersionCampaign)}
                  checked={newVersionCampaign}
                />
              </div>
            }
            <Button
              className="uploadButton"
              disabled={
                (this.props.programmatic === "programmatic" && this.checkMinimumBudget()) ||
                (this.props.programmatic != "programmatic" && priceTraditional <= 0) ||
                isProgrammaticProviders
              }
              onClick={() => {
                cleanFilters();
                if (user) {
                  if (
                    hasAccessRole(user.roles, [
                      UserRoleEnum.agencyMember,
                      UserRoleEnum.companyMember,
                    ])
                  ) {
                  if (this.props.client.id && this.props.brand.id) {
                      if(newVersionCampaign){
                        this.props.history.push({
                          pathname: "/campaignManager",
                          state: { from: "map" }
                        });
                      } else {
                        this.props.history.push("/videoUpload");
                      }

                    } else {
                      message.error(
                        this.props.t(
                          "You must select a customer and brand before starting a campaign launch"
                        ),
                        10
                      );
                    }
                  } else {
                      if(newVersionCampaign && ((this.state.usersCanAccessNewView) && !this.props.editCampaignActive)){
                        this.props.history.push("/campaignManager");
                      } else {
                        this.props.history.push("/videoUpload");
                      }
                  }
                } else {
                  this.props.setShouldPromptLogin(true, null);
                }
              }}
            >
              <span className="text-uppercase">
                {t("continue")}{" "}
              </span>
              <Icon type="right" theme="outlined" />
            </Button>
            {user &&
              user.roles.map((role, index) => {
                if (
                  role === UserRoleEnum.agencyMember ||
                  role === UserRoleEnum.companyMember
                ) {
                  return (
                    <div key={index}>
                      <Button
                        className="quoteButton"
                        onClick={() => {
                          if (this.props.client.id && this.props.brand.id) {
                            var displaysArray = [];
                            this.props.cart.map((display) =>
                              displaysArray.push(display.id)
                            );
                            var companyData = btoa(
                              JSON.stringify(this.props.user.company)
                            );
                            var displaysString = displaysArray.toString();
                            this.setState({
                              displays: displaysString,
                              companyData: companyData,
                            });
                            this.showQuoteModal();
                          } else {
                            message.error(
                              this.props.t(
                                "You must select a customer and brand before creating a quote"
                              ),
                              10
                            );
                          }
                        }}
                      >
                        <Icon type="solution" theme="outlined" />
                        <span className="text-uppercase">
                          {t("CREATE PROPOSAL")}{" "}
                        </span>
                      </Button>
                      {user && hasAccessRole(user.roles, [UserRoleEnum.superModeratorAgency, UserRoleEnum.superAdmin]) &&
                        <React.Fragment >
                          <Button
                            className="quoteButton"
                            onClick={this.exportXLS}
                            disabled={loadingExcel}
                          >
                            <Icon type="download" theme="outlined" />
                            <span className="text-uppercase">
                                {t("EXPORT EXCEL")}
                            </span>
                          </Button>
                          <Button
                            className="quoteButton"
                            onClick={this.setURLExportPNG}
                          >
                            <Icon type="export" theme="outlined" />
                            <span className="text-uppercase">
                                {t("EXPORT MAP")}
                            </span>
                          </Button>
                        </React.Fragment>}
                    </div>
                  );
                }
              })}
            {this.props.cart.length > 0 ? (
              <Row>
                {this.props.cart.map((display) => (
                  <Col key={display.id} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ScreenCardContainer display={display} />
                  </Col>
                ))}
              </Row>
            ) : (
              <div>{t("There are no more screens in the cart")}</div>
            )}
            <Button
              className="quoteButton"
              onClick={() => {
                this.props.cleanAudience();
                this.props.removeDisplaysCart();
                cleanArrayPolygon();
                cleanFilters();
              }}
            >
              <Icon type="delete" theme="outlined" />
              <span className="text-uppercase">
                {t("Empty cart")}
              </span>
            </Button>
          </div>
        </Drawer>
        <Modal
          visible={this.state.quoteModalVisible}
          onOk={this.handleLoginOk}
          onCancel={this.handleQuoteCancel}
          footer={null}
        >
          <div className="modalQuote">
            <h2>{t("Create Quote")}</h2>
          </div>
          <div className="modalContent">
            <Form.Item
              validateStatus={!!errors["nameQuote"] ? "error" : ""}
              help={errors["nameQuote"]}
            >
              <Input
                autoFocus
                name="nameQuote"
                className="modalInput"
                placeholder={t("Name")}
                value={this.state.nameQuote}
                onChange={this.handleInputChange}
                prefix={<Icon type="file-text" className="inputIcon" />}
                onPressEnter={() => this.handleCreateQuote()}
                autoComplete="off"
              />
            </Form.Item>
            <Button
              className="modalButton"
              onClick={() => this.handleCreateQuote()}
              loading={this.props.creatingQuote}
            >
              {t("Create")}
            </Button>
          </div>
        </Modal>
        <ModalInfoNewViewComponent
          onCancel={this.handleInfoNewViewCancel}
          visible={this.state.handlInfoNewViewOk}
          showMainModal={true}
        />
        <Modal
          visible={this.state.currenciesModalVisible && currencyExchangeRate}
          onCancel={this.hideCurrenciesModal}
          footer={null}
          title={t("Currency exchange rate")}
        >
          <div className="modalContent">
              <span style={{ fontSize: "16px"}}>
                <NumberFormat
                  tax={false}
                  value={1}
                  currency={currencyExchangeRate.base}
                />
                =&nbsp;
                <NumberFormat
                  tax={false}
                  value={currencyExchangeRate.rate}
                  currency={currencyExchangeRate.currency}
                />
              </span>
              <br/>
              <span>
                {t("Conversion generated at 11AM (Argentina time) today")}
              </span>
          </div>
        </Modal>
      </CartHolder>
    );
  }
}

const Extended = withTranslation()(CartComponent);
Extended.static = CartComponent.static;

export default withRouter(Extended);
