
import { connect } from 'react-redux'
import CardDSPScreensComponent from '../components/CardDSPScreensComponent'

const mapStateToProps = ({ display }) => ({
    cart: display.cart,
    dsp: display.dsp,
    programmatic: display.programmatic,
})

export default connect(mapStateToProps)(CardDSPScreensComponent)